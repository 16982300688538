// import { createApp } from 'vue';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from "@/pages/Home";
import Product from "@/pages/Product";
import ProductDetail from "@/pages/ProductDetail";
import ProductDetail2 from "@/pages/ProductDetail2";
import ProductDetail3 from "@/pages/ProductDetail3";
import DIHApp from "@/pages/DIHApp";
import aboutDih from "@/pages/aboutDih";
import searchPill from "@/pages/searchPill";
import qna from "@/pages/qna";
import notice from "@/pages/notice";
import noticeDetail from "@/pages/noticeDetail";
import question from "@/pages/question";
import questionDetail from "@/pages/questionDetail";
import login from "@/pages/login";
import qnaWrite from "@/pages/qnaWrite";
import adminLogin from "@/adminPages/adminLogin";
import health from "@/pages/health";
import Innovation from "@/pages/Innovation";
import Exhibition from "@/pages/Exhibition";


const routes = [
    { path: '/', component: Home, meta: { showHeaderFooter: true } },
    { path: '/Product', component: Product, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail', component: ProductDetail, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail2', component: ProductDetail2, meta: { showHeaderFooter: true } },
    { path: '/Product/Detail3', component: ProductDetail3, meta: { showHeaderFooter: true } },
    { path: '/App', component: DIHApp, meta: { showHeaderFooter: true } },
    { path: '/about', component: aboutDih, meta: { showHeaderFooter: true } },
    { path: '/searchPill', component: searchPill, meta: { showHeaderFooter: true } },
    { path: '/qna', component: qna, meta: { showHeaderFooter: true } },
    { path: '/notice', component: notice, meta: { showHeaderFooter: true } },
    { path: '/notice/noticeDetail/:noticeNum', component: noticeDetail, meta: { showHeaderFooter: true } },
    { path: '/question', component: question, meta: { showHeaderFooter: true } },
    { path: '/question/questionDetail/:qnaNum', component: questionDetail, meta: { showHeaderFooter: true } },
    { path: '/login', component: login, meta: { showHeaderFooter: true } },
    { path: '/qnaWrite', component: qnaWrite, meta: { showHeaderFooter: true } },
    { path: '/admin', component: adminLogin, meta: { showHeaderFooter: false  } },
    { path: '/health', component: health, meta: { showHeaderFooter: true } },
    { path: '/Innovation', component: Innovation, meta: { showHeaderFooter: true } },
    { path: '/Exhibition', component: Exhibition, meta: { showHeaderFooter: true } },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const store = createStore({
    state: {
        isAuthenticated: false, // 로그인 상태를 저장합니다.
        userEmail: '', // 사용자 이메일을 저장합니다.
        language: localStorage.getItem('language') || 'ko' // 로컬 스토리지에서 언어 설정을 가져옴
    },
    mutations: {
        setLanguage(state, lang) {
            state.language = lang;
            localStorage.setItem('language', lang); // 언어 설정을 로컬 스토리지에 저장
        },
        loginSuccess(state, userEmail) {
            state.isAuthenticated = true; // 로그인 상태를 변경합니다.
            state.userEmail = userEmail; // 사용자 이메일을 저장합니다.
            localStorage.setItem('userEmail', userEmail); // 로그인 상태를 로컬 스토리지에 저장
        },
        logout(state) {
            state.isAuthenticated = false; // 로그아웃 상태로 변경합니다.
            state.userEmail = ''; // 사용자 이메일을 초기화합니다.
            localStorage.removeItem('userEmail'); // 로그아웃 시 로컬 스토리지에서 사용자 정보 제거

            const now = new Date();
            const futureDate = new Date(now.getTime() + 12 * 60 * 60 * 1000); // 현재 시간으로부터 24시간 이후의 시간

            const expires = `expires=${futureDate.toUTCString()}`;
            document.cookie = `userEmail=; ${expires}; path=/;`;
        },

    },
    actions: {
        changeLanguage({ commit }, lang) {
            commit('setLanguage', lang);
        },
        handleLogin({ commit }, { userEmail }) {
            commit('loginSuccess', userEmail);
        },
        logout({ commit }) {
            commit('logout');
        },

    },
    getters: {
        currentLanguage: state => state.language,
        isAuthenticated: state => state.isAuthenticated,
        userEmail: state => state.userEmail
    },

});

router.beforeEach((to, from, next) => {
    const userEmail = localStorage.getItem('userEmail'); // 로그인 상태를 로컬 스토리지에서 확인
    if (userEmail) {
        store.commit('loginSuccess', userEmail);
    }

    next();
});


export default store;

createApp(App).use(router).use(store).mount('#app')

AOS.init();

// 초기화 함수 호출
// initializeUser();