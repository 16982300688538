<template>
<div class="qna">
    <section id="faq" class="faq section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Frequently Asked Questions</h2>
      <p>We will answer your questions about smart thermometers and Doctor in Home.</p>
    </div>

    <div class="accordion-list">
      <ul>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1">What kind of service is Doctor in Home?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
            <p>
              Doctor in Home is an app that allows you to record and view body temperature, medications, painful symptoms, and even photos of the inside of the ear at once.
              <br>Any family member can easily record by adding an account.
            </p>
          </div>
        </li>

        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed">Are thermometers also considered medical devices?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
            <p >
              yes. Thermometers belong to the second class of medical devices classified by the Ministry of Food and Drug Safety, and are certified and managed by the Ministry of Food and Drug Safety.
              <br>When purchasing a thermometer, check the ‘medical device’ and ‘certification number-model name’ markings on the product packaging, or use a thermometer certified by the Ministry of Food and Drug Safety. <br>You can check it on the ‘Medical Device Safety Bookstore(<span><a href="https://emedi.mfds.go.kr/search/data/MNU20237" target="_blank" style="padding: 0; display: inline;">https://emedi.mfds.go.kr</a></span>) ’ website.
              <br>
              <br>The smart thermometer is also a home medical device with Level 2 certification.
            </p>
          </div>
        </li>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed">How do I receive after-sales service if my thermometer breaks? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
            <p>
              Items will be collected via courier or sent directly. If the product's warranty period has expired for one year, repair costs may apply. Additionally, repairs due to customer negligence within the one year warranty period may incur repair costs.
              <br>Products purchased within 7 days can be exchanged or refunded.
            </p>
          </div>
        </li>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed">Do I have to purchase a thermometer to use the app?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
            <p>
              Anyone who needs to record body temperature and medication even without a thermometer can use the Doctor in Home app.
              <br>Just as you record your daily schedule, record your health as well.
            </p>
          </div>
        </li>
        <!-- <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" class="collapsed">스마트 체온계<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
            <p>
              Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
            </p>
          </div>
        </li> -->
      </ul>
      <br>
      <br>
      <br>
      <div class="section-title">
        <h2>Be sure to know!</h2>
        <p>We will answer your health-related questions.</p>
      </div>
      <ul>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-6">When should my child take fever reducers?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-6" class="collapse show" data-bs-parent=".accordion-list">
            <p>
              Since fever reducers are intended to improve symptoms rather than treat the cause, they do not treat the cause of the fever but only lower the symptom, which is fever.
              <br>
              <br>It is recommended to use it only when the child suffers from a high fever of 39 degrees or higher or is in poor condition. If you use fever reducers routinely or excessively, you may miss the progress of the infectious disease that is causing the fever.
              <br>
              <br>The body temperature that can be lowered by fever reducers is 1 to 1.5 degrees. Since this is a result that appears several hours after taking the drug, administering antipyretics in situations such as heat cramps is not effective.
            </p>
          </div>
        </li>

        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-7" class="collapsed">How should I take fever reducers?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-7" class="collapse" data-bs-parent=".accordion-list">
            <p>
              First, you need to check the ingredients of the fever reducer, and second, you need to check the appropriate dosage and dosage interval.
              <br>
              <br>Fever-reducing ingredients are largely divided into two types: acetaminophen (=paracetamol) and non-acetaminophen.
              <br>Medicines that contain acetaminophen include Tylenol, Cetofen, and Champ, while medicines that contain non-acetaminophen include ibufen, ibufen, and Carol. When stocking up on fever reducers as an emergency medicine, prepare only one medicine for each of the two types of ingredients.
              <br>
              <br>Both ingredients have the effect of lowering pain and fever by reducing substances that cause pain, fever, and inflammation. However, ingredients other than acetaminophen can also reduce inflammation symptoms. In addition to their antipyretic and analgesic effects, they can be taken for symptoms of otitis media or tonsillitis.
              <br>
              <br>The dosage interval varies depending on the ingredient: every 4 hours for acetaminophen, and every 6 hours for non-acetaminophen.
              If you took Tylenol 2 hours ago but your fever does not go down, you can take ibuprofen, which is called cross-dosing. This means taking medicine with different ingredients.
            </p>
          </div>
        </li>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-8" class="collapsed">What are the symptoms of otitis media?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-8" class="collapse" data-bs-parent=".accordion-list">
            <p>
              In the early stages, symptoms such as reddening of the eardrum, swelling of the eardrum, a feeling of something filling or blocking the ear, pain, hearing loss, and fever may appear.
              <br>
              <br>In acute otitis media, various general inflammatory symptoms such as fever, headache, vomiting, diarrhea, indigestion, loss of appetite, lethargy, anxiety, and nervousness appear gradually or rapidly, depending on the severity of the inflammatory condition or the symptoms of the disease.
              <br>
              <br>In young children, the external auditory canal is narrow, the eardrum is very slanted, and cooperation during examination is often difficult, making diagnosis difficult. If children are checked for symptoms using a smart thermometer at home by a guardian they trust rather than a doctor, it will be much easier to obtain cooperation and faster diagnosis and treatment will be possible.
            </p>
          </div>
        </li>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-9" class="collapsed">How should I take antibiotics?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-9" class="collapse" data-bs-parent=".accordion-list">
            <p>
              Antibiotics should only be prescribed and taken when the disease is bacterial.
              <br>If you take antibiotics when they are not needed, the beneficial intestinal bacteria responsible for digestion and immunity will die, so a doctor's diagnosis or examination is required.
              <br>
              <br>It is also dangerous to stop taking antibiotics when your symptoms have improved.
              <br>If you stop treatment arbitrarily, the infection may remain untreated and bacteria may develop resistance to the antibiotic.
              <br>Taking the remaining antibiotics later for similar symptoms is also not recommended, as the symptoms may be similar but the prescription may be different.
              <br>
              <br>You can receive a more accurate diagnosis from a doctor if you check the prescribed antibiotics and record the number and duration of antibiotics taken with Doctor in Home.
            </p>
          </div>
        </li>
        <li>
          <i class="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#accordion-list-10" class="collapsed">How dangerous are otitis media side effects?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="accordion-list-10" class="collapse" data-bs-parent=".accordion-list">
            <p >
              Acute otitis media is often a complication of a cold or allergy and is caused by a viral or bacterial infection.
              <br>Therefore, unless initial treatment is required, such as severe acute otitis media or children with acute otitis media within 24 months, natural recovery can be achieved with only painkillers without the use of antibiotics.
              <br>
              <br>Rarely, mastoiditis, in which bacterial otitis media spreads to the inner ear or nearby structures, such as the mastoid bone behind the ear; In very rare cases, it can spread to the brain, causing some people to develop complications such as meningitis or an abscess around the brain.
              <br>
              <br>If otitis media becomes prolonged and chronic, it can cause not only hearing loss but also auditory distortion and speech development disorders. This can be accompanied by acquired intellectual development disability, so children must be carefully observed for symptoms of otitis media.
            </p>
          </div>
        </li>
      </ul>
    </div>

  </div>
</section><!-- End Frequently Asked Questions Section -->
</div>
</template>
<script>
import 'boxicons/css/boxicons.min.css';

export default {
  name: 'qna'
}
</script>
<style scoped>

@import url('@/assets/css/dihApp.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');
/**
* Template Name: Appland
* Template URL: https://bootstrapmade.com/free-bootstrap-app-landing-page-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #585a61;
}

a {
  color: #5777ba;
  text-decoration: none;
}

a:hover {
  color: #7b94c9;
  text-decoration: none;
}

#faq {
  padding-top: 12rem;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #5777ba;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #748ec6;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #47536e;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #5777ba;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #5777ba;
  color: #fff;
  padding: 12px 25px;
  margin-left: 30px;
  color: #fff;
  line-height: 1;
  border-radius: 50px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  background: #748ec6;
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #47536e;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(51, 60, 79, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #47536e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #5777ba;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 140px 0 100px 0;
}

#hero::before {
  content: "";
  position: absolute;
  right: -100%;
  top: 20%;
  width: 250%;
  height: 200%;
  z-index: -1;
  background-color: #e8ecf5;
  transform: skewY(135deg);
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #5777ba;
}

#hero h2 {
  color: #515f7d;
  margin-bottom: 50px;
  font-size: 20px;
}

#hero .download-btn {
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 8px 24px 10px 46px;
  border-radius: 3px;
  transition: 0.5s;
  color: #fff;
  background: #47536e;
  position: relative;
}

#hero .download-btn:hover {
  background: #5777ba;
}

#hero .download-btn i {
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 8.5px;
}

#hero .download-btn+.download-btn {
  margin-left: 20px;
}

@media (max-width: 991px) {
  #hero {
    text-align: center;
  }

  #hero .download-btn+.download-btn {
    margin: 0 10px;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f2f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  margin-top: 75px;
  padding: 15px 0;
  background-color: #f6f8fb;
  min-height: 40px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 60px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# App Features
--------------------------------------------------------------*/
.features .content {
  padding: 30px 0;
}

.features .content .icon-box {
  margin-top: 25px;
}

.features .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .content .icon-box i {
  font-size: 48px;
  float: left;
  color: #5777ba;
}

.features .content .icon-box p {
  font-size: 15px;
  color: #979aa1;
  margin-left: 60px;
}

@media (max-width: 991px) {
  .features .image {
    text-align: center;
  }

  .features .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .features .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/
.details .content+.content {
  margin-top: 100px;
}

.details .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #47536e;
}

.details .content ul {
  list-style: none;
  padding: 0;
}

.details .content ul li {
  padding-bottom: 10px;
  font-size: 17px;
}

.details .content ul i {
  font-size: 24px;
  padding-right: 2px;
  color: #5777ba;
  line-height: 0;
}

.details .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-slide {
  transition: 0.3s;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    border: 6px solid #5777ba;
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
    margin-top: 10px;
    border-radius: 25px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 10px 30px 50px;
  min-height: 200px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e8ecf5;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5777ba;
  font-weight: 400;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5777ba;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .get-started-btn {
  background: #47536e;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
}

.pricing .get-started-btn:hover {
  background: #5777ba;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .get-started-btn {
  background: #5777ba;
}

.pricing .featured .get-started-btn:hover {
  background: #748ec6;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .accordion-list {
  padding: 0 100px;
}

.faq .accordion-list ul {
  padding: 0;
  list-style: none;
}

.faq .accordion-list li+li {
  margin-top: 15px;
}

.faq .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .accordion-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #b1c0df;
}

.faq .accordion-list .icon-show,
.faq .accordion-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .accordion-list .icon-show {
  display: none;
}

.faq .accordion-list a.collapsed {
  color: #343a40;
}

.faq .accordion-list a.collapsed:hover {
  color: #5777ba;
}

.faq .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  padding: 20px 40px;
  background: #f1f3f6;
  color: #47536e;
  text-align: center;
  border: 1px solid #fff;
}

.contact .info i {
  font-size: 48px;
  color: #9fb2d8;
  margin-bottom: 15px;
}

.contact .info h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact .info p {
  font-size: 15px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form label {
  margin-bottom: 5px;
  color: #8a8c95;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #5777ba;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #fff;
  border: 2px solid #5777ba;
  padding: 10px 24px;
  color: #5777ba;
  transition: 0.4s;
  border-radius: 50px;
  margin-top: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5777ba;
  color: #fff;
}

@media (max-width: 1024px) {
  .contact .php-email-form {
    padding: 30px 15px 15px 15px;
  }
}

@media (max-width: 768px) {
  .contact .php-email-form {
    padding: 15px 0 0 0;
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


</style>