<template>
  <div class="questionDetail">
    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1 v-if="$store.state.language === 'ko'">문의사항</h1>
            <h2 v-if="$store.state.language === 'ko'">제품 및 기타 문의 게시판입니다.</h2>

            <h1 v-if="$store.state.language === 'en'">Questions</h1>
            <h2 v-if="$store.state.language === 'en'">This is a bulletin board for products and other inquiries.</h2>
          </div>
        </div>

      </div>
    </section>
    <section id="faq" class="faq section-bg-noti">
      <div class="container" data-aos="fade-up">

        <div class="section-title-noti">

          <!-- <h2>자주 묻는 질문들</h2>
      <p>스마트 체온계와 닥터인홈의 궁금증을 해결해드립니다.</p> -->
        </div>

        <div class="notice-table">
          <div class="row">
            <div class="card">
              <div class="card-body">
                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                <!-- Table with hoverable rows -->
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="2" v-if="$store.state.language === 'ko'">상품정보</th>
                      <th colspan="2" v-if="$store.state.language === 'en'">Product Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ questions.qnaProduct }}</td>
                      <td style="width: 150px;">{{ questions.category }}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th scope="col">번호</th> -->
                      <th scope="col" colspan="4" style="border-bottom: none; font-size: 17px; color: #5777ba;">{{
                        questions.qnaTitle }}</th>
                    </tr>
                    <ul class="etcArea" style="border-bottom: solid 1px; border-color: gainsboro;">
                      <li>
                        <span class="txt">{{ extractUsername(questions.qnaWriter) }}</span>
                      </li>
                      <li>
                        <span class="txt">{{ questions.qnaDate }}</span>
                      </li>
                    </ul>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4" v-html="questions.qnaContent"></td>
                    </tr>
                    <tr v-if="showDeleteButton">
                      <td colspan="4">
                        <button @click="deleteQuestion">삭제</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- End Table with hoverable rows -->

              </div>
            </div>
          </div>
        </div>
        <div class="qnaList" style="text-align: center; margin: 20px;">
          <router-link to="/question" class="qnaList-btn" v-if="$store.state.language === 'ko'">목록</router-link>
          <router-link to="/question" class="qnaList-btn" v-if="$store.state.language === 'en'">List</router-link>
        </div>
      </div>

    </section>

  </div>
</template>
<script>
// import { question } from '@/scripts/question.js';

export default {
  name: 'questionDetail',
  data() {
    return {
      questions: {}
    };
  },
  created() {
    this.loadQuestions();
  },
  computed: {
    userEmail() {
      return this.$store.state.userEmail;
    },
    showDeleteButton() {
      // 작성자 이메일과 현재 로그인된 사용자의 이메일이 일치하는지 확인
      return this.questions.qnaWriter === this.userEmail;
    }
  },
  methods: {

    async loadQuestions() {
      // 1. 라우트 파라미터에서 질문 번호 가져오기
      const qnaNum = this.$route.params.qnaNum;

      // 2. IndexedDB에서 질문 데이터 가져오기
      const request = indexedDB.open('myDatabase', 1); // 데이터베이스 이름을 변경해야 할 수도 있습니다.
      request.onsuccess = event => {
        const db = event.target.result;
        const transaction = db.transaction('questions', 'readonly');
        const store = transaction.objectStore('questions');
        const request = store.get(parseInt(qnaNum));
        request.onsuccess = event => {
          this.questions = event.target.result;
        };
      };
      request.onerror = event => {
        console.error('Database error: ' + event.target.errorCode);
      };
    },
    extractUsername(email) {
      if (email) {
        return email.split('@')[0];
      } else {
        return '';
      }
    },
    // 삭제 버튼 클릭 시 실행될 메소드
    deleteQuestion() {
      // 1. 라우트 파라미터에서 질문 번호 가져오기
      const qnaNum = this.$route.params.qnaNum;

      // 2. IndexedDB에서 질문 데이터 삭제
      const request = indexedDB.open('myDatabase', 1); // 데이터베이스 이름을 변경해야 할 수도 있습니다.
      request.onsuccess = event => {
        const db = event.target.result;
        const transaction = db.transaction('questions', 'readwrite');
        const store = transaction.objectStore('questions');
        const deleteRequest = store.delete(parseInt(qnaNum));

        deleteRequest.onsuccess = () => {
          console.log('Question deleted successfully');
          this.$router.push('/question'); // 목록 페이지로 이동
        };

        deleteRequest.onerror = event => {
          console.error('Error deleting question: ' + event.target.errorCode);
        };
      };
      request.onerror = event => {
        console.error('Database error: ' + event.target.errorCode);
      };
    }
  }
}
</script>
<style scoped>
.etcArea li {
  display: inline-block;
  padding: 10px !important;
  padding-top: 0 !important;
  font-size: 15px;
}

.qnaList-btn {
  text-decoration: none;
  color: #5777ba;
}

.qnaList-btn:hover {
  color: #2c74df;
  /* 호버시 텍스트 색상 변경 */
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
  padding: 0 100px;
}

.faq .notice-table ul {
  padding: 0;
  list-style: none;
}

.faq .notice-table li+li {
  margin-top: 15px;
}

.faq .notice-table li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .notice-table a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}


.faq .notice-table p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg-noti {
  background-color: #fff;
  padding: 60px 0;
}

.section-title-noti {
  /* text-align: center; */
  padding-bottom: 30px;
}

.section-title-noti h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title-noti p {
  margin-bottom: 0;
}



/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
  padding: 0 100px;
}

.faq .notice-table ul {
  padding: 0;
  list-style: none;
}

.faq .notice-table li+li {
  margin-top: 15px;
}

.faq .notice-table li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .notice-table a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}


.faq .notice-table p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 30vh;
  /* background: url("@/assets/images/intro-3.png") top center; */
  background-color: #f2f5fa;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  /* background: rgba(0, 0, 0, 0.1); */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 10px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #5777ba;
}

#hero h1 span {
  color: #5777ba;
}

#hero h2 {
  color: rgba(86, 108, 110, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #5777ba;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #5777ba;
}

#hero .icon-box:hover {
  border-color: #5777ba;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>