<template>
    <div class="health">

        <!-- ======= Hero Section ======= -->
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1>Without Antibiotics<span>.</span></h1>
                        <!-- <h2>Take care of your children effectively.</h2> -->
                    </div>
                </div>

            </div>
        </section><!-- End Hero -->

        <main id="main">

            <!-- ======= About Section ======= -->
            <div v-if="currentSection === 'OtitisMedia'">
                <section id="services" class="services">
                    

                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-1.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                        
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1>Prevention and Management of Pediatric Otitis Media</h1>
                                                        <p class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        <h2>Prevention Strategies</h2>
                                                        <p>
                                                            Otitis media, a condition where fluid accumulates inside the eardrum due to bacterial infection, affects about 60% of children under the age of three at least once. This is because the Eustachian tubes in children are shorter, wider, and more horizontally positioned compared to adults, making them more prone to otitis media. Notably, more than half of the children who develop otitis media experience recurrent episodes, often more than three times. If not properly treated, otitis media can lead to hearing loss in infants and young children, highlighting the importance of proper management. Let's explore effective prevention and management strategies.
                                                        </p>
                                                        <p>
                                                            Since bacterial infections are a common cause of pediatric otitis media, vaccinations against pneumococcus or influenza can help in prevention. These vaccines have been reported to reduce the incidence of otitis media by about 43%.
                                                        </p>
                                                        <p>
                                                            In infants and young children, otitis media often develops as a complication of a cold, so it is important to prevent colds as well. Washing hands after coming home can help prevent upper respiratory infections that can lead to otitis media.

                                                        </p>
                                                        <p>
                                                            Treating sinusitis or rhinitis in advance is also advisable. Inflammation in the nose can make children more susceptible to otitis media, so maintaining nasal health is crucial.
                                                        </p>
                                                        <p>
                                                            Cigarette smoke is another significant cause of otitis media. It's important to ensure that children are not exposed to secondhand smoke by managing their environment carefully.
                                                        </p>
                                                        <h2>Management Strategies</h2>
                                                        <p>
                                                            When a child develops otitis media, the main concerns for parents are typically 'antibiotics', 'surgery', and 'hearing loss'. Contrary to the common belief that otitis media must be treated with antibiotics, the actual indications for antibiotics are severe ear pain, a high fever over 38.5 degrees Celsius, or an age of under six months. These are symptoms of acute otitis media, which is caused by bacterial infections and should be treated promptly with antibiotics. Otitis media with effusion, characterized by fluid in the ear without severe pain or fever, is usually viral and does not require antibiotics. Approximately 60-70% of otitis media cases fall into this category.
                                                        </p>
                                                        <p>
                                                            Otitis media with effusion can occur after an episode of acute otitis media when pus doesn't completely drain out, or it can develop without bacterial infection due to poor Eustachian tube function leading to a vacuum state and fluid secretion.
                                                        </p>
                                                        <p>
                                                            Most cases resolve naturally within two months. However, fluid accumulation inside the eardrum can cause hearing loss, which is critical during the language acquisition period in young children. Even short-term hearing loss can interfere with the development of the brain's language center, necessitating active management.
                                                        </p>
                                                        <p>
                                                            DoctorInHome offers consistent and convenient management solutions. By using endoscopic images to monitor the child's eardrum, you can observe the progression of otitis media, even when there is no high fever. You can also monitor the recovery progress while the child is taking anti-inflammatory pain relievers.
                                                        </p>
                                                        <h3>Start reducing antibiotic misuse with effective management from DoctorInHome.</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section>

            </div>

            <div v-if="currentSection === 'Antibiotics'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-3.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                        
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1>Beware of Antibiotic Resistance</h1>
                                                        <p class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        <h2>Understanding Antibiotics and Their Use</h2>
                                                        <p>
                                                            Antibiotics are used to treat infections caused by bacteria that invade our bodies. However, there are often cases of misuse due to the misconception that antibiotics can quickly cure all illnesses. Overuse and misuse of antibiotics lead to the development of antibiotic-resistant bacteria that can survive and proliferate even in the presence of antibiotics. This resistance is a serious concern because it can render antibiotics ineffective, resulting in potentially fatal infections such as sepsis and pneumonia. Both improper use and premature discontinuation of antibiotics significantly contribute to the spread of antibiotic-resistant bacteria.
                                                        </p>
                                                        <p>
                                                            Let's learn how to properly use antibiotics to prevent resistance.
                                                        </p>
                                                        <h2>Three Proper Ways to Take Antibiotics</h2>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-4.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <p>
                                                            Antibiotics are ineffective against viral illnesses such as the common cold or influenza. Since antibiotics target bacteria, they do not work against viruses. While bacterial infections such as pneumonia, sinusitis, and tonsillitis that develop during a cold may require antibiotics, taking antibiotics to prevent secondary bacterial infections is unnecessary.
                                                        </p>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-5.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <p>
                                                            It is crucial to complete the prescribed course of antibiotics. Stopping treatment prematurely when symptoms improve can lead to incomplete eradication of the infection and promote the development of resistant bacteria.
                                                        </p>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-6.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <p>
                                                            Antibiotics should be taken at regular intervals. Maintaining a consistent level of the antibiotic in the body is essential to effectively inhibit bacterial growth. Antibiotics are prescribed to ensure that their concentration remains stable in the bloodstream. If a dose is missed, take the next dose as scheduled without doubling up.
                                                        </p>
                                                        <h3>Remember these guidelines to use antibiotics correctly and prevent antibiotic resistance.</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>


            <div v-if="currentSection === 'Lifestyle'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-7.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                        
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1>Lifestyle Management to Prevent Otitis Media</h1>
                                                        <p class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        <p>
                                                            Another method to prevent otitis media is through lifestyle management. In addition to bacterial or viral infections, weakened immunity can trigger otitis media, so it is recommended to take immune-boosting supplements. Furthermore, adhering to proper preventive measures, such as regular handwashing and maintaining everyday hygiene, is essential. For children who already have otitis media, treatment along with lifestyle management is crucial.
                                                        </p>
                                                        <p>
                                                            Let’s explore which lifestyle practices can help in the prevention and management of otitis media.
                                                        </p>
                                                        <h2>Two Preventive Lifestyle Tips</h2>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-8.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <p>
                                                            For young children, bottles should only be used up to 12 months of age. Continuing to use bottles beyond the age of one can increase pressure on the Eustachian tubes, making children more susceptible to otitis media. Additionally, formula feeding should always be done while holding the baby. When a child drinks in a reclining position, the Eustachian tubes are more horizontal, making it easier for formula or foreign substances to enter the middle ear, potentially causing inflammation. Therefore, feeding at a 45-degree angle is recommended.
                                                        </p>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-9.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <!-- <p>
                                                            Avoid leaving children in daycare for extended periods or enrolling them in large daycare centers. Being in close contact with many other children increases the likelihood of catching a cold, which can lead to acute otitis media. Hence, reducing the risk of catching a cold is important.
                                                        </p> -->
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-10.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image"> 
                                                        </p> -->
                                                        <p>
                                                            Studies have shown that xylitol, in addition to preventing cavities, can also help prevent otitis media. Research by a team at the University of Toronto in Canada analyzed various studies and found that children who regularly consumed xylitol-containing gum, candies, or syrup had a 25% lower risk of developing otitis media compared to those who did not. The research indicated that xylitol-containing gum and candies were more effective at preventing otitis media than syrup. However, it was also found that xylitol did not benefit children who were already suffering from respiratory illnesses. While xylitol is effective in preventing otitis media, it only shows preventive effects in healthy children, so maintaining strong immunity is essential.
                                                        </p>
                                                        <h2>Lifestyle Management When a Child Has Otitis Media</h2>
                                                        <!-- <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-11.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p> -->
                                                        <p>
                                                            If a child has otitis media, using a bottle or straw can worsen the condition. It is better to use a spoon and cup.
                                                        </p>
                                                        
                                                        <p>
                                                            If the child's nose is blocked or runny, applying a warm towel to the nose or massaging the Yingxiang point next to the nostrils can relieve discomfort and help improve otitis media.
                                                        </p>
                                                        
                                                        <p>
                                                            Blowing the nose hard can put pressure on the ears, which is not good for otitis media. Also, blocking both nostrils and blowing hard can cause not only mucus but also bacteria and viruses in the nasal space to enter the ears, leading to or worsening otitis media. When blowing the nose, do it one side at a time to reduce the pressure on the ears.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>


            <div v-if="currentSection === 'Supplements'">
                <section id="portfolio" class="portfolio">
                    <section class="blog-section">
                        <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

                            <!-- <div class="row"> -->
                            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
                            <div class="image col-lg-6">
                                <div class="services-container" data-aos="fade-right" style="text-align: center;">
                                    <div class="serveices-intro image-container">
                                        <img src="@/assets/images/health-12.png" alt="" style="margin-bottom: 40px;" class="responsive-image">
                                        
                                    </div>


                                </div>
                            </div>
                            </div>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="blog-post">
                                                        <h1>What Are Effective Supplements for Otitis Media?</h1>
                                                        <p class="image-container">
                                                            <img src="@/assets/images/health-2.png" alt="" class="responsive-image">
                                                        </p>
                                                        
                                                        <h2>Supplements That Help Boost Immunity</h2>
                                                        <p class="image-container" style="text-align: center;">
                                                            <img src="@/assets/images/health-13.png" alt="" style="border-radius: 20px; margin-top: 30px; margin-bottom: 10px;" class="responsive-image">
                                                        </p>
                                                        <p>
                                                            Omega-3 fatty acids have anti-inflammatory effects, and specifically, EPA can reduce the inflammatory substance PGE2, which helps prevent the occurrence of otitis media.
                                                        </p>
                                                
                                                        <p>
                                                            Zinc is an essential trace element that plays a vital role in immune function and resistance to infections. Since the body cannot store zinc, it must be consumed regularly. Clinical trials have shown that zinc supplements can reduce the incidence of otitis media in children with severe malnutrition and compromised immunity. Therefore, taking zinc supplements can enhance immune function and prevent the occurrence of otitis media.
                                                        </p>
                                                
                                                        <p>
                                                            Probiotics increase beneficial bacteria in the gut, improve levels of inflammatory substances, and help prevent infections. Persistent stimulation of the gut by food can weaken the immune system, leading to allergic reactions and chronic otitis media. Studies have shown that eliminating foods that cause allergic reactions can reduce chronic otitis media symptoms by 85-93% and decrease recurrence rates by 70-80%. By consuming probiotics, the gut is relieved of stress, which can help prevent otitis media.
                                                        </p>
                                                        
                                                        <p>
                                                            Magnesium protects cells and soothes nerves, helping with tinnitus and dizziness. It is particularly effective in relieving stress, which is a major cause of reduced immunity. Therefore, it's beneficial to regularly consume magnesium.
                                                        </p>

                                                        <p>
                                                            The B vitamins can prevent not only tinnitus but also its complications. Folic acid (B9) prevents oxidative stress in the cochlea, protecting against damage and reducing the risk of hearing loss. Vitamins B1, B2, and B6 stabilize nerves in the ear, preventing dizziness and ringing in the ears caused by tinnitus. Vitamin B12 deficiency is common in tinnitus patients, and adequate supplementation is necessary. Research has shown that about 47% of chronic tinnitus patients have a vitamin B12 deficiency. Those who follow a vegetarian diet may lack vitamin B12, so supplementation is important.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                </section><!-- End About Section -->
            </div>



        </main><!-- End #main -->

        <!-- ======= Footer ======= -->

    </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
    name: 'health',
    data() {
        return {
            currentSection: 'about' // Default section is 'about'
        };
    },
    methods: {
        showSection(section) {
            this.currentSection = section;
        },
        checkHash() {
            const hash = this.$route.hash.replace('#', '');
            if (hash) {
                this.showSection(hash);
            }
        },
        navigateToSection(section) {
            this.$router.push({ hash: `#${section}` });
        }
    },
    mounted() {
        // Swiper 초기화
        new Swiper('.swiper-container', {
            // Swiper 옵션 설정
            slidesPerView: 1,
            watchOverflow: true,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        this.checkHash(); // 초기 로드 시 해시 체크
        this.$router.afterEach(() => {
            this.checkHash(); // 라우트 변경 시 해시 체크
        });
    },
}
</script>
<style scoped>
@import url('@/assets/css/about.css');

/**
  * Template Name: Gp
  * Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
  * Updated: Mar 17 2024 with Bootstrap v5.3.3
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  */

  .image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.nav-btn.hidden {
    display: none;
    /* hidden 클래스를 가진 요소를 숨깁니다 */
}

.blog-section {
    padding: 40px 0;
    background: #f9f9f9;
}

.blog-post {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.blog-post h2 {
    font-size: 2em;
    margin-top: 30px;
    color: #555;
}

.blog-post p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
}

hr {
    border-top: none;
}

.nav-btn {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 2rem;
    font-family: 'Noto Sans KR', sans-serif;
}

.nav-btn button {
    font-weight: 600;
    /* 폰트 굵기 설정 */
    font-size: 15px;
    padding: 0.75rem 1.5rem;
    /* 버튼의 안쪽 여백 */
    border: none;
    /* 기본 테두리 제거 */
    background-color: #17a2b8;
    /* 배경색 설정 */
    color: #fff;
    /* 글자 색상 */
    border-radius: 5px;
    /* 모서리를 둥글게 */
    cursor: pointer;
    /* 커서를 포인터로 변경 */
    transition: background-color 0.3s ease;
    /* 배경색 전환 효과 */
}

.nav-btn button:hover {
    background-color: #138496;
    /* 호버 시 배경색 변경 */
}

.nav-btn button:focus {
    outline: none;
    /* 포커스 시 외곽선 제거 */
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
    /* 포커스 시 그림자 추가 */
}


.services-control-prev,
.services-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    background: none;
    border: 0;
    @include transition($carousel-control-transition);


    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }
}

.services-control-prev {
    left: 0;
    background-image: ;
}

.services-control-next {
    right: 0;
    background-image: ;
}

@media (max-width: 991px) {
    .services .image {
        max-width: 80%;
        background-size: cover;
        background-position: center;
    }

    .services .image img {
        max-width: 100%;
        background-size: cover;
        background-position: center;
    }
}

/*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
body {
    color: #444444;
}

a {
    color: #ffc451;
    text-decoration: none;
}

a:hover {
    color: #ffd584;
    text-decoration: none;
}



/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ffc451;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #151515;
    line-height: 0;
}

.back-to-top:hover {
    background: #151515;
}

.back-to-top:hover i {
    color: #ffc451;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #151515;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 0px);
    left: calc(50% - 30px);
    border: 6px solid #ffc451;
    border-top-color: #151515;
    border-bottom-color: #151515;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/

#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 9px 0;
    background: rgba(254, 254, 243, 0.8);
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgba(254, 254, 243, 0.8);
}

#header .logo {
    font-size: 32px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: #fff;
}

#header .logo a span {
    color: #ffc451;
}

#header .logo img {
    max-height: 40px;
}

/*--------------------------------------------------------------
  #  Get Startet Button
  --------------------------------------------------------------*/
.get-started-btn {
    color: #fff;
    border-radius: 4px;
    padding: 7px 25px 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    border: 2px solid #ffc451;
}

.get-started-btn:hover {
    background: #ffbb38;
    color: #343a40;
}

@media (max-width: 992px) {
    .get-started-btn {
        padding: 7px 20px 8px 20px;
        margin-right: 15px;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    /* align-items: center; */

}

.navbar li {
    /* position: relative; */
}

.navbar a,
.navbar a:focus {
    /* display: flex; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #ffc451;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #151515;
    font-weight: 400;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #151515;
    background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
    color: #151515;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    background-color: #ffc451;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 80vh;
    background: url("@/assets/images/intro-31.png") top center;
    background-size: auto 100%;
    position: relative;
}

#hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 74px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
}

#hero h1 span {
    color: #ffc451;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #ffc451;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #ffc451;
}

#hero .icon-box:hover {
    border-color: #ffc451;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #ffde9e;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Noto Sans KR', sans-serif;
    color: #151515;
}



/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features {
    padding-top: 20px;
}

.features .icon-box {
    padding-left: 15px;
}

.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.features .icon-box i {
    font-size: 48px;
    float: left;
    color: #ffc451;
}

.features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}

.features .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #ffc451;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
}

.services .icon-box .icon i {
    color: #151515;
    font-size: 28px;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #151515;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
    color: #ffc451;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    color: #151515;
    background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(21, 21, 21, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #ffc451;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts .content {
    padding: 30px 0;
}

.counts .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #151515;
}

.counts .content p {
    margin-bottom: 0;
}

.counts .content .count-box {
    padding: 20px 0;
    width: 100%;
}

.counts .content .count-box i {
    display: block;
    font-size: 36px;
    color: #ffc451;
    float: left;
}

.counts .content .count-box span {
    font-size: 36px;
    line-height: 30px;
    display: block;
    font-weight: 700;
    color: #151515;
    margin-left: 50px;
}

.counts .content .count-box p {
    padding: 15px 0 0 0;
    margin: 0 0 0 50px;
    font-size: 14px;
    color: #3b3b3b;
}

.counts .content .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #3b3b3b;
    font-size: 15px;
    transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
    color: #626262;
}

.counts .image {
    background: url("@/assets/images/about-1.png") center center no-repeat;
    background-size: cover;
    min-height: 2000px;
}

@media (max-width: 991px) {
    .counts .image {
        text-align: center;
    }

    .counts .image img {
        max-width: 80%;
    }
}

@media (max-width: 667px) {
    .counts .image img {
        max-width: 100%;
    }
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
    padding: 80px 0;
    background: url("@/assets/images/intro-3.png") no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
    margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    text-align: center;
    color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: rgba(255, 255, 255, 0.6);
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
    opacity: 1;
}

@media (min-width: 1024px) {
    .testimonials {
        background-attachment: fixed;
    }
}

@media (min-width: 992px) {
    .testimonials .testimonial-item p {
        width: 80%;
    }
}


/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: whitesmoke;
    min-height: 40px;
    margin-top: 74px;
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #2f2f2f;
    content: "/";
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 68px;
    }

    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: #151515;
    border-bottom: 1px solid #222222;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
    color: #ffc451;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Noto Sans KR', sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #292929;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #ffc451;
    color: #151515;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffc451;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ffc451;
    color: #151515;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #ffcd6b;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}
</style>