<template>
    <div v-if="editor">
        <!-- 볼드, 이태릭, 취소선등 에디터 옵션. 아이콘은 Material icon에서 원하는 아이콘을 가져왔다. -->
        <button @click="editor.chain().focus().toggleBold().run()"
            :disabled="!editor.can().chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }">
            <i class="bi bi-type-bold"></i>
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()"
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }">
            <i class="bi bi-type-italic"></i>
        </button>
        <button @click="editor.chain().focus().toggleStrike().run()"
            :disabled="!editor.can().chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }">
            <i class="bi bi-type-strikethrough"></i>
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            <i class="bi bi-type-h1"></i>
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            <i class="bi bi-type-h2"></i>
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
            <i class="bi bi-type-h3"></i>
        </button>
        <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
            <i class="bi bi-arrow-counterclockwise"></i>
        </button>
        <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
            <i class="bi bi-arrow-clockwise"></i>
        </button>
    </div>

    <!-- 에디터 영역 -->
    <editor-content :editor="editor" style="height: 90%" />
</template>
<script>
import StarterKit from "@tiptap/starter-kit"; // starterKit를 사용하면 에디터 extensions를 하나씩 import하지 않고 starterKit에 내장되어 있는 extensions을 사용할 수 있다.
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
    components: {
        EditorContent,
    },

    props: {
        modelValue: {
            type: String,
            default: "",
        },
    },

    emits: ["update:modelValue"],

    data() {
        return {
            editor: null,
        };
    },

    watch: {
        modelValue(value) {
            // HTML 그대로 서버에 저장하려는 방식을 선택
            const isSame = this.editor.getHTML() === value;

            // JSON으로 변환시 사용
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [StarterKit, Image],
            content: this.modelValue,
            onUpdate: () => {
                this.$emit("update:modelValue", this.editor.getHTML());

                // JSON일 경우
                // this.$emit("update:modelValue", this.editor.getJSON());
            },
        });
    },

    beforeUnmount() {
        this.editor.destroy();
    },
};
</script>
<style scoped>
.ProseMirror {
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 6px;
    overflow: scroll;

    >*+* {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }
}
</style>