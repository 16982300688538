<template>
  <div class="adminLogin">
    <header role="navigation" data-fh-type="top"
      class="menu-toptable3d navbar navbar-fheader navbar-fixed-top navbar-li">
      <div class="container" data-fh-align="center">

        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-target="#mobile-nav">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/config" id="site-home" data-recommend="220 X 60">
            <img
              src="https://lh3.googleusercontent.com/OriAR5OmtYECs70I-OxvLxIz1YennVJzAzJkdacdgw9nIdvSVtX0IjJgeSmm2NCQ4lTss5FvDvN-apgc3R8-KvcOQTOgf-kHwrcTVPNDljlJ5x0Po556ZA"
              data-attach="true" id="tpl-logo-img" class="" alt="toptable_ci.png">
            <span id="tpl-logo-text" class="hide" data-selector="#tpl-logo-text">CREATORLINK</span>
          </a>
        </div>

        <div class="collapse navbar-collapse navbar-menu-toptable3d">
          <ul class="nav navbar-nav hide preloading navbar-right" id="tpl-menu">




















            <li class="active"><a href="#">HOME</a></li>
            <li class=""><a href="#">PORTFOLIO</a></li>
            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">ABOUT <i class="fa fa-caret-down"></i></a>
              <ul class="dropdown-menu">
                <li><a href="#">BIOGRAPHY</a></li>
                <li><a href="#">CLIENT</a></li>
                <li><a href="#">HISTORY</a></li>
              </ul>
            </li>
            <li class=""><a href="#">CONTACT</a></li>




















          </ul>
        </div>

      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: 'adminLogin'
}
</script>
<style scoped>
</style>