<template>
    <div class="dihApp">
        <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
      <div>
        <h1>Download Doctor in Home APP</h1>
        <h2>The beginning of a personalized record for your child with the family</h2>
        <a href="#" class="download-btn"><i class="bx bxl-play-store"></i> Google Play</a>
        <a href="#" class="download-btn"><i class="bx bxl-apple"></i> App Store</a>
      </div>
    </div>
    <div class="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
      <img src="@/assets/images/app-1.png" class="img-fluid" alt="">
    </div>
  </div>
</div>

</section><!-- End Hero -->

<main id="main">

<!-- ======= App Features Section ======= -->
<section id="features" class="features">
  <div class="container">

    <div class="section-title">
      <h2>APP function introduction</h2>
      <p>All family members can become guardians and record their child's health. Check your fever, medications, and otitis media status at a glance and start personalized care.</p>
    </div>

    <div class="row no-gutters">
      <div class="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
        <div class="content d-flex flex-column justify-content-center">
          <div class="row">
            <div class="col-md-6 icon-box" data-aos="fade-up">
              <i class='bx bx-child'></i>
              <h4>All family members can use one account</h4>
              <p>Sign up for membership only once! All you have to do is enter the guardian information by adding an account, and everyone can take care of your child together.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
              <i class='bx bx-home-heart' ></i>
              <h4>Otitis media can be diagnosed at home</h4>
              <p>It is possible to diagnose otitis media at home by providing comparative photos of each stage of otitis media.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
              <i class='bx bxs-thermometer'></i>
              <h4>Record body temperature and medication together</h4>
              <p>Have you saved a medicine bag to remember which medicine you are prescribed each time? Now, just by taking a photo, you can record the prescribed medication and add a separate medication record to check it at any time.</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
              <i class='bx bxs-capsule' ></i>
              <h4>Medication management</h4>
              <p>Antibiotic ingredients are labeled separately for easy identification. If you enter your weight and height information, the recommended standard dosage will be provided so you can take it quickly at a pharmacy.</p>
            </div>
            <!-- <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
              <i class="bx bx-atom"></i>
              <h4>Molestiae dolor</h4>
              <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
            </div>
            <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-id-card"></i>
              <h4>Explicabo consectetur</h4>
              <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
        <img src="@/assets/images/app-2.svg" class="img-fluid" alt="">
      </div>
    </div>

  </div>
</section><!-- End App Features Section -->

<!-- ======= Details Section ======= -->
<section id="details" class="details">
  <div class="container">

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4">
        <img src="@/assets/images/app-3.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-4">
        <h3>1. Record your body temperature with a thermometer.</h3>
        <p>
          Please measure your body temperature several times and then fill it out so that we can check your temperature accurately.
        </p>
        <ul>
          <li><i class="bi bi-check"></i> Write down the temperature indicated on the thermometer.</li>
          <li><i class="bi bi-check"></i> Select today's date and current time when your temperature was measured.</li>
          <li><i class="bi bi-check"></i> The guardian directly records the child's confirmed symptoms.</li>
          <li><i class="bi bi-check"></i> If you have a high fever, click the 'Select Imaging and Otitis Media Stage' button to take pictures. If it's normal, click Save to complete the recording.</li>
        </ul>
        <p>
          Symptoms recorded by all guardians sharing an account can be checked at once in the app.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4 order-1 order-md-2">
        <img src="@/assets/images/app-4.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1">
        <h3>2. Please select the stage of otitis media after endoscopic imaging.</h3>
        <p>
          Although it is possible to select only the otitis media stage without taking a picture, it is more accurate to take a picture and compare.
        </p>
        <ul>
          <li><i class="bi bi-check"></i> When you click the ear capture button, it is linked to the machine.</li>
          <li><i class="bi bi-check"></i> You can take a new photo with the Retake button and the existing photo will be deleted.</li>
          <li><i class="bi bi-check"></i> Check the symptoms of otitis media at each stage and compare them with the photos taken to make your selection.</li>
          <li><i class="bi bi-check"></i> When you click Save, you will be taken to a selection window where you can take pictures of the other side as well.</li>
          <li><i class="bi bi-check"></i> If you save them all, the selected otitis media stage will be recorded along with your temperature and symptoms.</li>
        </ul>
        <p>
          Depending on the stage of otitis media you select, you will be advised whether or not to visit the hospital.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4">
        <img src="@/assets/images/app-5.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5">
        <h3>3. Please record your medication.</h3>
        <p>With the medication history, you can find the medication that is best for your child.</p>
        <ul>
          <li><i class="bi bi-check"></i> Click the Medication Record button and proceed to the recording screen.</li>
          <li><i class="bi bi-check"></i> When taking a photo of a prescription envelope, the drug name is automatically recorded.</li>
          <li><i class="bi bi-check"></i> If you took a separate medication, click Enter directly, search the drug name, and select the medication you took.</li>
          <li><i class="bi bi-check"></i> If you click Save, you can check the entered medication time, hospital visited, and prescribed medication.</li>
        </ul>
        <p>
          Drug names containing antibiotic ingredients are marked separately so they can be easily identified.
        </p>
      </div>
    </div>

    <div class="row content" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <div class="col-md-4 order-1 order-md-2" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <img src="@/assets/images/app-6.png" class="img-fluid" alt="">
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1">
        <h3>4. Take care of your child with Doctor in Home.</h3>
        <p>
          You can continuously check your child's status with the app alarm.
        </p>
        <ul>
          <li><i class="bi bi-check"></i> Temperature recording determines how long it takes for a person to go from high fever to normal body temperature.</li>
          <li><i class="bi bi-check"></i> An alarm sounds so you can measure your child's temperature so you don't miss out on your child's condition.</li>
          <li><i class="bi bi-check"></i> You can check your child's health with just a scroll.</li>
        </ul>
        <p>
          Doctor in Home, start right now.
        </p>
      </div>
    </div>

  </div>
</section><!-- End Details Section -->



</main><!-- End #main -->
    </div>
</template>
<script>
import 'boxicons/css/boxicons.min.css';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import {onMounted} from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    name: 'DIHApp',
    setup() {
    onMounted(() => {
      AOS.init({
        // Optional settings
        duration: 1000,
      });
    });
  },
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        color: {
            type: String,
            default: 'currentColor'
        }
    },
    mounted(){
      this.swiper = new Swiper('.testimonials-slider', {
        pagination: {
          el: '.swiper-pagination',
        },
      });
    },
}
</script>
<style scoped>

@import url('@/assets/css/dihApp.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');
/**
* Template Name: Appland
* Template URL: https://bootstrapmade.com/free-bootstrap-app-landing-page-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #585a61;
}

a {
  color: #5777ba;
  text-decoration: none;
}

a:hover {
  color: #7b94c9;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #5777ba;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #748ec6;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #47536e;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #5777ba;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #5777ba;
  color: #fff;
  padding: 12px 25px;
  margin-left: 30px;
  color: #fff;
  line-height: 1;
  border-radius: 50px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  background: #748ec6;
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #47536e;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(51, 60, 79, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #47536e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #5777ba;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #5777ba;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 140px 0 100px 0;
}

#hero::before {
  content: "";
  position: absolute;
  right: -100%;
  top: 20%;
  width: 250%;
  height: 200%;
  z-index: -1;
  background-color: #e8ecf5;
  transform: skewY(135deg);
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #5777ba;
}

#hero h2 {
  color: #515f7d;
  margin-bottom: 50px;
  font-size: 20px;
}

#hero .download-btn {
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 8px 24px 10px 46px;
  border-radius: 3px;
  transition: 0.5s;
  color: #fff;
  background: #47536e;
  position: relative;
}

#hero .download-btn:hover {
  background: #5777ba;
}

#hero .download-btn i {
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 8.5px;
}

#hero .download-btn+.download-btn {
  margin-left: 20px;
}

@media (max-width: 991px) {
  #hero {
    text-align: center;
  }

  #hero .download-btn+.download-btn {
    margin: 0 10px;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f2f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5777ba;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  margin-top: 75px;
  padding: 15px 0;
  background-color: #f6f8fb;
  min-height: 40px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 60px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# App Features
--------------------------------------------------------------*/
.features .content {
  padding: 30px 0;
}

.features .content .icon-box {
  margin-top: 25px;
}

.features .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .content .icon-box i {
  font-size: 48px;
  float: left;
  color: #5777ba;
}

.features .content .icon-box p {
  font-size: 15px;
  color: #979aa1;
  margin-left: 60px;
}

@media (max-width: 991px) {
  .features .image {
    text-align: center;
  }

  .features .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .features .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/
.details .content+.content {
  margin-top: 100px;
}

.details .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #47536e;
}

.details .content ul {
  list-style: none;
  padding: 0;
}

.details .content ul li {
  padding-bottom: 10px;
  font-size: 17px;
}

.details .content ul i {
  font-size: 24px;
  padding-right: 2px;
  color: #5777ba;
  line-height: 0;
}

.details .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-slide {
  transition: 0.3s;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    border: 6px solid #5777ba;
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
    margin-top: 10px;
    border-radius: 25px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 10px 30px 50px;
  min-height: 200px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #e8ecf5;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #5777ba;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #5777ba;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5777ba;
  font-weight: 400;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5777ba;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .get-started-btn {
  background: #47536e;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
}

.pricing .get-started-btn:hover {
  background: #5777ba;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .get-started-btn {
  background: #5777ba;
}

.pricing .featured .get-started-btn:hover {
  background: #748ec6;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .accordion-list {
  padding: 0 100px;
}

.faq .accordion-list ul {
  padding: 0;
  list-style: none;
}

.faq .accordion-list li+li {
  margin-top: 15px;
}

.faq .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .accordion-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #b1c0df;
}

.faq .accordion-list .icon-show,
.faq .accordion-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .accordion-list .icon-show {
  display: none;
}

.faq .accordion-list a.collapsed {
  color: #343a40;
}

.faq .accordion-list a.collapsed:hover {
  color: #5777ba;
}

.faq .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .accordion-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  padding: 20px 40px;
  background: #f1f3f6;
  color: #47536e;
  text-align: center;
  border: 1px solid #fff;
}

.contact .info i {
  font-size: 48px;
  color: #9fb2d8;
  margin-bottom: 15px;
}

.contact .info h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact .info p {
  font-size: 15px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form label {
  margin-bottom: 5px;
  color: #8a8c95;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #5777ba;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #fff;
  border: 2px solid #5777ba;
  padding: 10px 24px;
  color: #5777ba;
  transition: 0.4s;
  border-radius: 50px;
  margin-top: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5777ba;
  color: #fff;
}

@media (max-width: 1024px) {
  .contact .php-email-form {
    padding: 30px 15px 15px 15px;
  }
}

@media (max-width: 768px) {
  .contact .php-email-form {
    padding: 15px 0 0 0;
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



</style>