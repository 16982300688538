<template>
  <div class="aboutDih">

    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div class="col-xl-6 col-lg-8">
            <h1>Doctor in Home<span>.</span></h1>
            <h2>Take care of your children effectively.</h2>
          </div>
        </div>

      </div>
    </section><!-- End Hero -->

    <main id="main">

      <nav class="nav-btn hidden">
        <button @click="showSection('about')">About</button>
        <button @click="showSection('services')">중이염</button>
        <button @click="showSection('portfolio')">편도염</button>
        <button @click="showSection('contact')">오시는 길</button>
      </nav>

      <!-- ======= About Section ======= -->
      <div v-if="currentSection === 'services'">
        <section id="services" class="services">
          <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

            <!-- <div class="row"> -->
            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
            <div class="image col-lg-6">
              <div class="services-container" data-aos="fade-right" style="text-align: center;">
                <div class="serveices-intro">
                  <img src="@/assets/images/about-51.png" alt="" style="margin-bottom: 40px;">
                  <!-- <h1>닥터인홈<span>.</span></h1> -->
                  <p>
                    Otitis media is often considered a 'child disease', but it is actually a disease that can affect all
                    ages. I would like to introduce you to what otitis media is, what side effects it has, and the
                    risks.
                  </p>
                  <img src="@/assets/images/about-52.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                  <p>You may have heard of otitis media, but do you know exactly
                    what it is?</p>
                  <img src="@/assets/images/eng.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                  <p><span style="font-weight: 600;">What is otitis media?</span> <br>It is a disease in which
                    inflammation
                    occurs in the middle ear part of the ear, mainly due to eustachian tube dysfunction or external
                    infection. <br>It can be classified into acute otitis media, exudative otitis media, chronic otitis
                    media, etc., <br>and can be divided into various stages depending on the degree of progression.</p>
                  <br>
                  <br>
                  <h2 style="font-weight: 600;">Acute otitis media</h2>
                  <p> Inflammation of the middle ear due to infection may cause pus
                    to build up behind the eardrum. Pain, fever, and hearing loss are common symptoms and usually occur
                    after an upper respiratory tract infection.</p>
                  <br>
                  <h2 style="font-weight: 600;">Otitis media with effusion (mucous otitis media)</h2>
                  <p> This form is characterized by a buildup of mucus or fluid in
                    the middle ear, but causes less severe pain or signs of infection. This condition, also called “glue
                    ear,” can cause hearing loss.</p>
                  <h2 style="font-weight: 600;">Chronic otitis media</h2>
                  <p> This is when otitis media persists for a long time or occurs
                    repeatedly. This may be accompanied by a hole in the eardrum, persistent discharge, or hearing loss.
                  </p>
                  <br>
                  <img src="@/assets/images/about-54.jpg" alt=""
                    style="margin-bottom: 40px; margin-top: 20px; width: 50%;">
                </div>
                <div id="servicesCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="@/assets/images/eng1.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng2.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng3.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/eng4.png" alt="" class="bd-placeholder-img" width="100%" height="100%"
                        aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#servicesCarousel"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/prev-icon.png') + ')' }"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#servicesCarousel"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"
                      :style="{ backgroundImage: 'url(' + require('@/assets/images/next-icon.png') + ')' }"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

                <div class="serveices-intro">
                  <br>
                  <p>If otitis media is left untreated, it can lead to hearing loss,
                    <br><span style="font-weight: 600;">serious speech and learning difficulties, and structural damage
                      to the ear.</span>
                  </p>
                  <p>Especially in children, these complications can have <span style="font-weight: 600;">a fatal
                      impact on cognitive and language development,</span> <br>and even in adults, they can
                    significantly reduce
                    the quality of life. Therefore, early diagnosis and active treatment of otitis media <br>go beyond
                    simple health care and are essential measures for preventing serious health problems.</p>
                  <br>
                  <br>
                  <p>Treatment depends on the cause of the inflammation and the type
                    of otitis media. Usually, antibiotic treatment, pain management, <br>and surgical approach if
                    necessary. Active treatment and regular checkups are important, <br>which can effectively manage and
                    prevent otitis media.</p>
                  <br>
                  <br>
                  <p>
                    Flu cases were reported to be around 900,000 in 2022, rising to around 1.9 million in 2023,
                    <br>after
                    the lifting of mask-wearing due to COVID-19. This suggests that otitis media is
                    <br>as common as flu compared to the number of otitis media patients. The average number of patients
                    from 2012 to 2016 was approximately 2.3 million and from 2018 to 2022 approximately 1.6 million,
                    <br>indicating that the prevention and management of otitis media remains an important challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </section>

        <!-- ======= Services Section ======= -->
        <section id="services" class="services">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Services</h2>
              <p>Why does otitis media need management?</p>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div class="icon-box">
                  <div class="icon"><i class='bx bxs-virus' ></i></div>
                  <h4>
                    <a href="##">Very common disease</a>
                  </h4>
                  <p>It is a disease that affects 2 out of 3 people at least once
                    and 1 out of 3 people at least 3 times under the age of 3.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                data-aos-delay="200">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-trending-up'></i></div>
                  <h4>
                    <a href="##">High recurrence rate</a>
                  </h4>
                  <p>If the first onset occurs before the age of 2, there is a high
                    risk of repeated acute otitis media. Continuous observation is required to check for recurrence for
                    up
                    to 3 months after treatment of otitis media.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
                data-aos-delay="300">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-brightness' ></i></div>
                  <h4>
                    <a href="##">Complications of recurrent otitis media</a>
                  </h4>
                  <p>It can cause delays and decline in speech, language, and
                    cognitive abilities, and can cause hearing loss or damage.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-angry' ></i></div>
                  <h4>
                    <a href="##">High-risk group requiring early treatment</a>
                  </h4>
                  <p>If you have sensorineural hearing loss, uncorrectable visual
                    impairment, Down syndrome or craniofacial deformity, cleft palate, autism, pervasive developmental
                    disorder, language development disorder, and cognitive decline are accompanied by otitis media.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-capsule' ></i></div>
                  <h4>
                    <a href="##">Antibiotic resistance</a>
                  </h4>
                  <p>In a domestic reality where the incidence of acute otitis media
                    caused by antibiotic-resistant bacteria is increasing, careful observation is very important to
                    avoid
                    administering antibiotics.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box">
                  <div class="icon"><i class='bx bx-clinic' ></i></div>
                  <h4>
                    <a href="##">Until it is cured</a>
                  </h4>
                  <p>When diagnosing otitis media with effusion, the initial
                    treatment is to observe the progress for up to 3 months. Afterwards, it is necessary to determine
                    the
                    condition of the eardrum, hearing condition, and language development problems to determine whether
                    to
                    require additional treatment.</p>
                </div>
              </div>

            </div>

          </div>
        </section><!-- End Services Section -->
      </div>

      <div v-if="currentSection === 'portfolio'">
        <section id="portfolio" class="portfolio">
          <div class="container" data-aos="fade-up" style="display: flex; justify-content: center;">

            <!-- <div class="row"> -->
            <!-- data-aos="fade-right" :style="{ backgroundImage: 'url(' + require('@/assets/images/about-55.png') + ')' }" style="background-size: cover; height: 218rem; width: 60%;" -->
            <div class="image col-lg-6">
              <div class="services-container" data-aos="fade-right" style="text-align: center;">
                <div class="serveices-intro">
                  <img src="@/assets/images/about-21.png" alt="" style="margin-bottom: 40px;">
                  <!-- <h1>닥터인홈<span>.</span></h1> -->
                  <p>
                    There is an inseparable disease from otitis media. It's tonsillitis. How much do you know about
                    tonsillitis?
                  </p>
                  <p>Tonsillitis is often caused by viral infections such as colds, with children under the age of 9
                    accounting for about 25 percent of all patients. Let's find out what side effects of tonsillitis, a
                    common disease like this, and how it is treated.</p>
                  <img src="@/assets/images/about-22.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                  <p>Tonsillitis is a disease that causes inflammation in the tonsils, which are lymphatic tissues
                    located inside the neck and behind the nose. <br>The tonsils are the mouth and the nasal cavity
                    It plays an important role in defending against pathogens invading through and protects the body by
                    creating antibodies against bacteria. <br>Tonsillitis is largely acute tonsillitis, chronic
                    tonsillitis, and peri-tonsillar abscesses
                    It's divided.</p>
                  <br>
                  <br>
                  <h2 style="font-weight: 600;">Acute tonsillitis</h2>
                  <p> They include a high fever, headache, ear pain, chills, and neck pain, and are accompanied by pain
                    in the joints of the bones. Within a few days, my throat hurts so much that I can't even swallow my
                    saliva.</p>
                  <br>
                  <h2 style="font-weight: 600;">Chronic tonsillitis</h2>
                  <p> Acute tonsillitis symptoms appear repeatedly, and foreign body sensation persists in the neck. It
                    may affect the ear canal and be accompanied by complications such as <span
                      style="font-weight: 600;"> otitis media</span>.</p>
                  <br>
                  <img src="@/assets/images/about-24.png" alt="" style="margin-bottom: 40px; margin-top: 20px;">
                </div>

                <div class="serveices-intro">
                  <br>
                  <p>Frequent tonsillitis can cause a variety of side effects. <br>Especially in young children, <span
                      style="font-weight: 600;">It can affect growth and basic immunity</span> and can also cause
                    changes in the face <span style="font-weight: 600;">look</span>.</p>
                  <p>Changes in the voice or snoring can occur, and the risk of sinusitis and otitis media
                    increases.<br>It can be accompanied by external stress, such as a decrease in the secretion of
                    growth hormones due to a decrease in the quality of sleep, or changes in the appearance of teeth and
                    faces due to oral breathing. <br> Adults can also feel uncomfortable due to side effects such as
                    airway stenosis and continuous neck foreign body due to enlarged tonsils, so proper treatment is
                    necessary.</p>
                  <br>
                  <br>
                  <p>The basic treatment of tonsillitis is medication with antibiotics and anti-inflammatory drugs. If
                    you have a high fever or if complications are suspected, you may need to administer fluids and
                    antibiotics. <br>If you do not respond to antibiotic treatment, you should consider tonsillectomy.
                    <br>Especially if tonsillitis interferes with a child's growth or causes dental problems, you need
                    to consider surgery. It is recommended that surgery be performed after the age of 3 to 4 considering
                    the burden of treatment and the impact on immune function.</p>
                  <br>
                  <br>
                  <p>
                    Tonsillitis is a common disease, but it should not be taken lightly, especially for young children.
                    <br>Appropriate treatment and care are needed when early symptoms appear because it can affect
                    growth, immune function, and facial features. <br>If your child often gets tonsillitis, it is
                    important to consult an expert to find an appropriate treatment.
                  </p>
                </div>
              </div>
            </div>

          </div>

        </section><!-- End About Section -->
      </div>



      <!-- ======= Counts Section ======= -->
      <div v-if="currentSection === 'about'">
        <section id="about" class="about">


          <!-- </div> -->
          <div class="container" data-aos="fade-up">

            <div class="row">
              <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <img src="@/assets/images/about.png" class="img-fluid" alt="">
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <h3>I take care of my child’s health in my own hands. <br>Doctor in
                  Home will create it together.</h3>
                <p>
                  Doctor in Home developed a smart thermometer to enable the diagnosis of otitis media at home, which
                  could be confirmed during hospital visits, and through the linkage of the thermometer and APP, the
                  children's fever and otitis media can be checked and effectively managed in real time.
                </p>
                <ul>
                  <li><i class="ri-check-double-line"></i> After the first
                    diagnosis, you have to visit the hospital again for symptoms that do not improve without knowing the
                    reason.</li>
                  <li><i class="ri-check-double-line"></i> Prescription medications
                    change every time you visit a different hospital.</li>
                  <li><i class="ri-check-double-line"></i> Our child was neglected
                    until symptoms of otitis media appeared.</li>
                </ul>
                <p>
                  In this day and age where many people have dual incomes, we made it easy to manage your temperature
                  with
                  just a smart thermometer, without having to go to the hospital every day or collect envelopes for
                  prescriptions. <br>Doctor in Home will work hard until children suffering from the aftereffects of
                  otitis media disappear.
                </p>
              </div>
            </div>

          </div>
        </section><!-- End Counts Section -->
      </div>


      <!-- ======= Contact Section ======= -->
      <div v-if="currentSection === 'contact'">
        <section id="contact" class="contact">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Contact</h2>
              <p>way to come <span style="font-size: 15px;">R&D</span></p>
            </div>

            <div>
              <iframe style="border:0; width: 100%; height: 270px;"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d395.7577601338173!2d126.87669656026587!3d37.48286135078961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61fd7c865237%3A0x4ba22bf028fb0968!2z7ISc7Jq47Yq567OE7IucIOq4iOyynOq1rCDqsIDsgrDrj5kgNDU5LTI0IDYxM-2YuA!5e0!3m2!1sko!2skr!4v1713162750497!5m2!1sko!2skr"
                frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="row mt-5">

              <div class="col-lg-4">
                <div class="info">
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Address:</h4>
                    <p>Gasan-dong, Geumcheon-gu, Seoul 459-24 Room o613, Doctor in
                      Home</p>
                  </div>

                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>otiton-medical@otiton-medical.com</p>
                  </div>

                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4>Phone:</h4>
                    <p>+82 70 4607 2681</p>
                  </div>

                </div>

              </div>
            </div>


          </div>
          <hr class="featurette-divider">
          <div class="container" data-aos="fade-up">

            <div class="section-title">
              <h2>Contact</h2>
              <p>way to come <span style="font-size: 15px;">HQ</span></p>
            </div>

            <div>
              <iframe style="border:0; width: 100%; height: 270px;"
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d279.85156985052896!2d126.88160604643211!3d37.4805721224175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61db5780a2c5%3A0x7685dbe7d685fa6!2z7Jqw66a865287J207Jio7Iqk67C466asIEHrj5k!5e0!3m2!1sko!2skr!4v1717397423004!5m2!1sko!2skr"
                frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="row mt-5">

              <div class="col-lg-4">
                <div class="info">
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Address:</h4>
                    <p>Room 1011, Building A, Woolim Lions Valley, 168 Gasan Digital 1-ro, Geumcheon-gu, Seoul</p>
                  </div>

                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>otiton-medical@otiton-medical.com</p>
                  </div>

                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4>Phone:</h4>
                    <p>+82 70 4607 2681</p>
                  </div>

                </div>

              </div>
            </div>


          </div>
        </section><!-- End Contact Section -->
      </div>

      <!-- ======= Cta Section ======= -->
      <section id="cta" class="cta">
        <div class="container" data-aos="zoom-in">

          <div class="text-center">
            <h3>Doctor in Home APP</h3>
            <p> Doctor in Home where all family members can record</p>
            <a class="cta-btn" href="#">Go to install</a>
          </div>

        </div>
      </section><!-- End Cta Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->

  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'aboutDih',
  data() {
    return {
      currentSection: 'about' // Default section is 'about'
    };
  },
  methods: {
    showSection(section) {
      this.currentSection = section;
    },
    checkHash() {
      const hash = this.$route.hash.replace('#', '');
      if (hash) {
        this.showSection(hash);
      }
    },
    navigateToSection(section) {
      this.$router.push({ hash: `#${section}` });
    }
  },
  mounted() {
    // Swiper 초기화
    new Swiper('.swiper-container', {
      // Swiper 옵션 설정
      slidesPerView: 1,
      watchOverflow: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    this.checkHash(); // 초기 로드 시 해시 체크
    this.$router.afterEach(() => {
      this.checkHash(); // 라우트 변경 시 해시 체크
    });
  },
}
</script>
<style scoped>
@import url('@/assets/css/about.css');

/**
* Template Name: Gp
* Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
.nav-btn.hidden {
  display: none;
  /* hidden 클래스를 가진 요소를 숨깁니다 */
}

hr {
  border-top: none;
}

.nav-btn {
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  gap: 2rem;
  font-family: 'Noto Sans KR', sans-serif;
}

.nav-btn button {
  font-weight: 600;
  /* 폰트 굵기 설정 */
  font-size: 15px;
  padding: 0.75rem 1.5rem;
  /* 버튼의 안쪽 여백 */
  border: none;
  /* 기본 테두리 제거 */
  background-color: #17a2b8;
  /* 배경색 설정 */
  color: #fff;
  /* 글자 색상 */
  border-radius: 5px;
  /* 모서리를 둥글게 */
  cursor: pointer;
  /* 커서를 포인터로 변경 */
  transition: background-color 0.3s ease;
  /* 배경색 전환 효과 */
}

.nav-btn button:hover {
  background-color: #138496;
  /* 호버 시 배경색 변경 */
}

.nav-btn button:focus {
  outline: none;
  /* 포커스 시 외곽선 제거 */
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
  /* 포커스 시 그림자 추가 */
}


.services-control-prev,
.services-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  background: none;
  border: 0;
  @include transition($carousel-control-transition);


  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

.services-control-prev {
  left: 0;
  background-image: ;
}

.services-control-next {
  right: 0;
  background-image: ;
}

@media (max-width: 991px) {
  .services .image {
    max-width: 80%;
    background-size: cover;
    background-position: center;
  }

  .services .image img {
    max-width: 100%;
    background-size: cover;
    background-position: center;
  }
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #444444;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}



/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ffc451;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}

.back-to-top:hover {
  background: #151515;
}

.back-to-top:hover i {
  color: #ffc451;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #151515;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid #ffc451;
  border-top-color: #151515;
  border-bottom-color: #151515;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 9px 0;
  background: rgba(254, 254, 243, 0.8);
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(254, 254, 243, 0.8);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #ffc451;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
#  Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  color: #fff;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #ffc451;
}

.get-started-btn:hover {
  background: #ffbb38;
  color: #343a40;
}

@media (max-width: 992px) {
  .get-started-btn {
    padding: 7px 20px 8px 20px;
    margin-right: 15px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  /* align-items: center; */

}

.navbar li {
  /* position: relative; */
}

.navbar a,
.navbar a:focus {
  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #ffc451;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  background-color: #ffc451;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #151515;
  background-color: #ffc451;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  color: #151515;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  background-color: #ffc451;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 80vh;
  background: url("@/assets/images/intro-3.png") top center;
  background-size: auto 100%;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
}

#hero h1 span {
  color: #ffc451;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}

#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}

#hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
  color: #ffc451;
}

#hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Noto Sans KR', sans-serif;
  color: #151515;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 8px 26px;
  position: relative;
  font-size: 17px;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: #ffc451;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding-top: 20px;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  background-color: #ddd;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  padding-top: 20px;
}

.features .icon-box {
  padding-left: 15px;
}

.features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}

.features .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ffc451;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #ffc451;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("@/assets/images/intro-3.png") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #ffc451;
  border-color: #ffc451;
  color: #151515;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(21, 21, 21, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #ffc451;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #ffc451;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(21, 21, 21, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .content {
  padding: 30px 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #ffc451;
  float: left;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #151515;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-size: 14px;
  color: #3b3b3b;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #626262;
}

.counts .image {
  background: url("@/assets/images/about-1.png") center center no-repeat;
  background-size: cover;
  min-height: 2000px;
}

@media (max-width: 991px) {
  .counts .image {
    text-align: center;
  }

  .counts .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("@/assets/images/intro-3.png") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc451;
  opacity: 1;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  transition: ease-in-out 0.3s;
  color: #484848;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a:hover {
  color: #151515;
  background: #ffc451;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #151515;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  background: #ffc451;
  color: #151515;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ffc451;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #ffc451;
  border: 0;
  padding: 10px 24px;
  color: #151515;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #ffcd6b;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: whitesmoke;
  min-height: 40px;
  margin-top: 74px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #2f2f2f;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }

  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

#footer {
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #151515;
  border-bottom: 1px solid #222222;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
  color: #ffc451;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Noto Sans KR', sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ffc451;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ffc451;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ffc451;
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #ffcd6b;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
</style>