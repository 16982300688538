<template>
  <div class="searchPill">
    <section id="hero" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row gy-4 d-flex" style="justify-content: center;">
          <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center" style="width: 80%;">
            <h2 data-aos="fade-up">Here is information on medications you can purchase directly from the pharmacy</h2>
            <p data-aos="fade-up" data-aos-delay="100">You can check the drug name
              and usage instructions by clicking on the corresponding symptom.
              <br>For detailed usage and dosage instructions, ask your pharmacist.
              <br>You can receive an accurate diagnosis at a hospital.
            </p>

            <!-- :class 를 이용하여 바인딩 기능으로 버튼이 클릭된 상태를 유지되게 함 -->
            <div class="frame">
              <button @click="fetchDrugs('meddle ear')" :class="['custom-btn btn-7', { 'selected-btn': selectedBtn === 'meddle ear' }]">Otitis media</button>
              <button @click="fetchDrugs('cold')" :class="['custom-btn btn-7', { 'selected-btn': selectedBtn === 'cold' }]">Runny nose/cold</button>
              <button @click="fetchDrugs('tonsillitis')" :class="['custom-btn btn-7', { 'selected-btn': selectedBtn === 'tonsillitis' }]">Tonsillitis</button>
              <button @click="fetchDrugs('heartburn')" :class="['custom-btn btn-8', { 'selected-btn': selectedBtn === 'heartburn' }]">Heartburn</button>
              <button @click="fetchDrugs('Anti-inflammatory')" :class="['custom-btn btn-8', { 'selected-btn': selectedBtn === 'Anti-inflammatory' }]" style="font-size:1.15rem !important;">Anti-inflammatory analgesic</button>
              <button @click="fetchDrugs('reduces fever')" :class="['custom-btn btn-8', { 'selected-btn': selectedBtn === 'reduces fever' }]">Antipyretic analgesic</button>
            </div>
            <!-- <div class="frame">
              <h2>{{ selectedButton }}</h2>
            </div> -->


            <!-- 검색 바 -->
            <form v-if="showSearchBar" @submit.prevent="searchMedications" class="form-search d-flex align-items-stretch mb-3" data-aos="fade-up" data-aos-delay="200">
              <input v-model="searchQuery" type="text" class="form-control" placeholder="Enter drug name to search" @input="validateSearchInput">
              <button type="submit" class="btn btn-primary searchBtn">Search</button>
            </form>

            <div class="sort">
              <button @click="sortData('asc')">ascending order</button>
              <button @click="sortData('desc')">descending order</button>
            </div>

            <!-- 로딩 애니메이션 -->
            <div v-if="loading" class="loading-overlay">
              <div class="droplet_spinner">
                <div class="droplet"></div>
                <div class="droplet"></div>
                <div class="droplet"></div>
              </div>
            </div>

            <!-- 검색 결과 또는 전체 목록 표시 -->
            <div v-if="searchQuery.trim()">
              <!-- 검색어가 있을 때 검색 결과 표시 -->
              <div v-if="filteredMedications.length > 0">
                <table>
                  <thead>
                    <tr>
                      <th>Brand Name</th>
                      <th>Manufacturer Name</th>
                      <th>Indications and Usage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(drug, index) in paginatedFilteredMedications" :key="index">
                      <td>{{ drug.openfda.brand_name ? drug.openfda.brand_name.join(', ') : 'N/A' }}</td>
                      <td>{{ drug.openfda.manufacturer_name ? drug.openfda.manufacturer_name.join(', ') : 'N/A' }}</td>
                      <td>
                        <span v-if="drug.indications_and_usage && drug.indications_and_usage.join(' ').length > 100">
                          <span v-if="!drug.showMoreIndications">{{ drug.indications_and_usage.join(' ').substring(0, 100) }}...</span>
                          <span v-else>{{ drug.indications_and_usage.join(' ') }}</span>
                          <button @click="toggleShowMore(drug, 'indications')" class="more-button">{{ drug.showMoreIndications ? 'Show Less' : 'Show More' }}</button>
                        </span>
                        <span v-else>{{ drug.indications_and_usage ? drug.indications_and_usage.join(' ') : 'N/A' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p>No results found.</p>
              </div>
            </div>
            <div v-else>
              <!-- 검색어가 없을 때 전체 목록 표시 -->
              <div v-if="paginatedDrugs.length > 0">
                <table>
                  <thead>
                    <tr>
                      <th>Brand Name</th>
                      <th>Manufacturer Name</th>
                      <th>Indications and Usage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(drug, index) in paginatedDrugs" :key="index">
                      <td>{{ drug.openfda.brand_name ? drug.openfda.brand_name.join(', ') : 'N/A' }}</td>
                      <td>{{ drug.openfda.manufacturer_name ? drug.openfda.manufacturer_name.join(', ') : 'N/A' }}</td>
                      <td>
                        <span v-if="drug.indications_and_usage && drug.indications_and_usage.join(' ').length > 100">
                          <span v-if="!drug.showMoreIndications">{{ drug.indications_and_usage.join(' ').substring(0, 100) }}...</span>
                          <span v-else>{{ drug.indications_and_usage.join(' ') }}</span>
                          <button @click="toggleShowMore(drug, 'indications')" class="more-button">{{ drug.showMoreIndications ? 'Show Less' : 'Show More' }}</button>
                        </span>
                        <span v-else>{{ drug.indications_and_usage ? drug.indications_and_usage.join(' ') : 'N/A' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 검색 결과에 대한 페이지네이션 -->
<div v-if="filteredTotalPages > 1 && searchQuery.trim() && filteredMedications.length > 0">
  <nav aria-label="Search results page navigation" style="text-align: center;">
    <ul class="pagination" style="justify-content: center;">
      <li @click="prevFilteredPage" class="page-item" :class="{ 'disabled': filteredCurrentPage === 1 }">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li v-for="page in filteredTotalPages" :key="page" class="page-item" :class="{ 'active': filteredCurrentPage === page }">
        <a class="page-link" href="#" @click.prevent="goToFilteredPage(page)">{{ page }}</a>
      </li>
      <li @click="nextFilteredPage" class="page-item" :class="{ 'disabled': filteredCurrentPage === filteredTotalPages }">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>

<!-- 전체 목록 페이지네이션 -->
<section v-if="totalPages > 1 && !searchQuery.trim()">
  <nav aria-label="Page navigation example" style="text-align: center;">
    <ul class="pagination" style="justify-content: center;">
      <li @click="prevPage" class="page-item" :class="{ 'disabled': currentPage === 1 }">
        <a class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li v-for="page in visiblePages" :key="page" class="page-item" :class="{ 'active': currentPage === page }">
        <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
      </li>
      <li class="page-item" @click="nextPage" :class="{ 'disabled': currentPage === totalPages }">
        <a class="page-link" href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</section>
    <p data-aos="fade-up" data-aos-delay="100" style="padding: 2rem;">Source | U.S. Department of Health and Human Services - Food and Drug Administration</p>
  </div>
</template>
<script>
import 'boxicons/css/boxicons.min.css';
// import axios from "axios";
// import {medications} from '@/scripts/medications.js';

export default {
  name: 'searchPill',
  data() {
    return {
      selectedBtn: '', // 선택된 버튼을 추적하는 데이터
      drugs: [], //  배열
      searchQuery: '', // 검색어전체 데이터
      currentPage: 1,
      filteredCurrentPage: 1,   // 검색 결과 목록 페이지
      pageSize: 20,
      totalPages: null,
      loading: false,
      maxVisiblePages: 5, // 한 번에 표시할 페이지 수
      totalCount: 0, // 전체 데이터 개수
      sortOrder: 'asc',
      showSearchBar: false, // 검색바 보이기 여부
      filteredMedications: [] // 검색 결과를 저장할 변수
    };
  },
  watch: {
    searchQuery() {
      this.currentPage = 1;  // 검색어가 변경될 때 페이지를 1로 초기화
      this.searchMedications();
    }
  },
  methods: {
    async fetchDrugs(condition) {
      this.selectedBtn = condition;
      this.currentPage = 1; // 새로운 검색 시 페이지를 초기화
      this.loading = true;
      this.searchQuery = ''; // 검색어 초기화
      this.showSearchBar = true; // 버튼 클릭 시 검색 바 표시
      this.drugs = []; // 이전 데이터 초기화
      this.totalCount = 0;

      const baseUrl = `https://api.fda.gov/drug/label.json?search=indications_and_usage:${condition}+AND+openfda.product_type.exact:"HUMAN+OTC+DRUG"+AND+openfda.route.exact:"ORAL"`;

      try {
        let limit = 100; 
        let skip = 0;
        let dataFetched = true;

        while (dataFetched) {
          const url = `${baseUrl}&limit=${limit}&skip=${skip}`;
          const response = await fetch(url);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            this.drugs.push(...data.results.map(drug => ({
              ...drug,
              showMoreIndications: false,
            })));
            skip += limit;
            this.totalCount += data.results.length;
          } else {
            dataFetched = false;
          }

          if (data.results.length < limit) {
            dataFetched = false;
          }
        }

        this.sortData(this.sortOrder); // 데이터를 가져온 후 정렬
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        this.filteredMedications = this.drugs; // 초기 상태에서 전체 목록을 보여줌
      } catch (error) {
        console.error('Error fetching the drug data:', error);
        this.drugs = [];
        this.totalPages = 0;
      } finally {
        this.loading = false;
      }
    },
    sortData(order) {
      this.sortOrder = order;
      this.drugs.sort((a, b) => {
        const brandA = a.openfda.brand_name ? a.openfda.brand_name[0].toUpperCase() : '';
        const brandB = b.openfda.brand_name ? b.openfda.brand_name[0].toUpperCase() : '';
        
        if (brandA < brandB) return order === 'asc' ? -1 : 1;
        if (brandA > brandB) return order === 'asc' ? 1 : -1;
        return 0;
      });
    },
   // 검색 입력 필드에서 알파벳만 허용하는 메서드
   validateSearchInput(event) {
      // 정규 표현식으로 알파벳만 필터링
      const inputValue = event.target.value;
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, ''); // 알파벳, 숫자 및 공백만 허용
    },
    searchMedications() {
    // 검색어가 있으면 검색된 결과에 대해서만 페이지 계산
    if (this.searchQuery.trim()) {
      this.filteredMedications = this.drugs.filter(drug =>
        drug.openfda.brand_name && drug.openfda.brand_name.join(' ').toLowerCase().includes(this.searchQuery.trim().toLowerCase())
      );
      this.filteredCurrentPage = 1; // 검색어 입력 시 페이지 초기화
    } else {
      this.filteredMedications = this.drugs;
      this.filteredCurrentPage = 1;
    }
  },
    toggleShowMore(drug, field) {
      if (field === 'indications') {
        drug.showMoreIndications = !drug.showMoreIndications;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
      }
    },
    // 검색 결과의 다음 페이지로 이동
  nextFilteredPage() {
    if (this.filteredCurrentPage < this.filteredTotalPages) {
      this.filteredCurrentPage++;
      window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
    }
  },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
      }
    },
    // 검색 결과의 이전 페이지로 이동
  prevFilteredPage() {
    if (this.filteredCurrentPage > 1) {
      this.filteredCurrentPage--;
      window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
    }
  },
  goToFilteredPage(page) {
    this.filteredCurrentPage = page;
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
  },
    goToPage(page) {
      this.currentPage = page;
      window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드럽게 스크롤
    });
    },
  },
  computed: {
    paginatedDrugs() {
      // 현재 페이지에 해당하는 데이터를 반환합니다.
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.drugs.slice(startIndex, endIndex);
    },
    paginatedFilteredMedications() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredMedications.slice(startIndex, endIndex);
    },
    // 검색 결과에 맞는 페이지 수 계산
  filteredTotalPages() {
    return Math.ceil(this.filteredMedications.length / this.pageSize);
  },
    visiblePages() {
      const pages = [];
      let startPage = Math.max(1, this.currentPage - Math.floor(this.maxVisiblePages / 2));
      let endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1);

      // 끝 페이지가 전체 페이지 수보다 작을 때 시작 페이지 조정
      if (endPage - startPage + 1 < this.maxVisiblePages) {
        startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    },
  }
};
</script>
<style scoped>
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');
@import url('@/assets/css/dihApp.css');

/* 검색 바 */
.form-search {
  justify-content: center;
}

.form-control {
  margin: 20px;
  margin-right: none !important;
  height: 4.8rem;
  width: 40% !important;
  font-size: 1.1875rem;
  font-weight: 700 !important;
}

.searchBtn {
  width: 10% !important;
  font-size: 1.1875red;
  height: 4.8rem;

}

.more-button {
  background-color: inherit;
  color: #d36e10;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
}


/* 로딩 애니메이션 */
.droplet_spinner {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.droplet_spinner .droplet {
  width: 15px;
  height: 15px;
  margin: 0 5px;

  background-color: #ffffff;
  /* 초기 색상 하얀색 */
  border-radius: 50%;
  transform-origin: center bottom;

  animation: bounce 1.2s cubic-bezier(0.3, 0.01, 0.4, 1) infinite, colorChange 1.2s cubic-bezier(0.3, 0.01, 0.4, 1) infinite;
}

.droplet_spinner .droplet:nth-child(1) {
  animation-delay: -0.4s;
}

.droplet_spinner .droplet:nth-child(2) {
  animation-delay: -0.2s;
}

.droplet_spinner .droplet:nth-child(3) {
  animation-delay: 0s;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes colorChange {

  0%,
  100% {
    background-color: #ffffff;
    /* 하얀색 */
  }

  50% {
    background-color: #FFCC00;
    /* 진한 노란색 */
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort {
  text-align: right;
}

.sort button {
  background-color: inherit;
  color: #000103;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 10px;
}

.sort button:hover {
  color: #f5c38a;
  /* 호버시 텍스트 색상 변경 */
}

.more-button {
  background-color: inherit;
  color: #d36e10;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
}

.frame .selected-btn {
  background-color: transparent;
  /* 선택된 버튼의 배경색 */
  color: #f3ba84;
  /* 선택된 버튼의 텍스트 색상 */
}

.frame .selected-btn:before,
.frame .selected-btn:after {
  height: 0;
  background-color: transparent;
}

.btn-7 {
  background: #f3ba84;
  color: #fff;
  /* line-height: 42px; */
  padding: 0;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-7:hover {
  background: transparent;
  color: #f3ba84;
}

.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;
  background: #f3ba84;
  transition: all 0.3s ease;
}

.btn-7:before {
  top: 0;
}

.btn-7:after {
  bottom: 0;
}

.btn-7:hover:before,
.btn-7:hover:after {
  height: 0;
  background-color: #f3ba84;
}

.btn-8 {
  background: #f1d586;
  color: #fff;
  /* line-height: 42px; */
  padding: 0;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn-8:hover {
  background: transparent;
  color: #f1d586;
}

.btn-8:before,
.btn-8:after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 50%;
  right: 0;
  z-index: -1;
  background: #f1d586;
  transition: all 0.3s ease;
}

.btn-8:before {
  top: 0;
}

.btn-8:after {
  bottom: 0;
}

.btn-8:hover:before,
.btn-8:hover:after {
  height: 0;
  background-color: #f1d586;
}

.frame {
  width: 100%;
  margin-bottom: 40px;
  text-align: left;
}

button {
  margin: 20px;
  outline: none;
}

.custom-btn {
  width: 18.75rem;
  height: 3.125rem;
  font-size: 1.1875rem;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.1s ease;
  position: relative;
  display: inline-block;
}

table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

th {
  text-align: left;
}

thead {
  /* font-weight: bold; */
  color: #5e554c;
  background: #c9b29c;
}

td,
th {
  padding: 1em .5em;
  vertical-align: middle;
}

td {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background: #fff;
}

a {
  color: #73685d;
}

@media all and (max-width: 768px) {

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  th {
    text-align: right;
  }

  table {
    position: relative;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  thead {
    float: left;
    white-space: nowrap;
  }

  tbody {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
  }

  tr {
    display: inline-block;
    vertical-align: top;
  }

  th {
    border-bottom: 1px solid #a39485;
  }

  td {
    border-bottom: 1px solid #e5e5e5;
  }


}

.first-column {
  color: #ee9e4f;
  /* font-weight: bold; */
}

/**
* Template Name: Logis
* Template URL: https://bootstrapmade.com/logis-bootstrap-logistics-website-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */

/* Colors */
:root {
  --color-default: #0a0d13;
  --color-primary: #0d42ff;
  --color-secondary: #0e1d34;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  /* font-family: var(--font-default); */
  /* color: var(--color-default); */
}

a {
  /* color: var(--color-primary); */
  text-decoration: none;
}

a:hover {
  color: #406aff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 140px 0 80px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs .page-header:before {
  content: "";
  background-color: rgba(14, 29, 52, 0.8);
  position: absolute;
  inset: 0;
}

.breadcrumbs .page-header h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs .page-header p {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs nav {
  background-color: #f3f6fc;
  padding: 20px 0;
}

.breadcrumbs nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
}

.breadcrumbs nav ol a {
  color: var(--color-primary);
  transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
  text-decoration: underline;
}

.breadcrumbs nav ol li+li {
  padding-left: 10px;
}

.breadcrumbs nav ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4278cc;
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(13, 66, 255, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 30px 0;
}

.header.sticked {
  background: rgba(14, 29, 52, 0.9);
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(14, 29, 52, 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: #fff;
  font-family: var(--font-primary);
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
  }

  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: #2756ff;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: var(--color-secondary);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(14, 29, 52, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .get-a-quote,
  .navbar .get-a-quote:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    border-radius: 4px;
    margin: 15px;
    color: #fff;
  }

  .navbar .get-a-quote:hover,
  .navbar .get-a-quote:focus:hover {
    color: #fff;
    background: rgba(13, 66, 255, 0.8);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #19335c;
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    margin-right: 10px;
  }

  .mobile-nav-hide {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(14, 29, 52, 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services .service-item {
  position: relative;
  height: 100%;
  margin-bottom: 20px;
}

.featured-services .service-item .icon {
  margin-right: 20px;
}

.featured-services .service-item .icon i {
  color: #001f8d;
  font-size: 48px;
  transition: 0.3s;
}

.featured-services .service-item:hover .icon {
  background: #fff;
}

.featured-services .service-item:hover .icon i {
  color: var(--color-primary);
}

.featured-services .service-item .title {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--color-secondary);
  transition: 0.3s;
}

.featured-services .service-item .description {
  font-size: 15px;
  color: #132848;
  margin-bottom: 10px;
}

.featured-services .service-item .readmore {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  transition: 0.3s;
  font-weight: 700;
  font-size: 14px;
}

.featured-services .service-item .readmore i {
  margin-left: 8px;
}

.featured-services .service-item:hover .title,
.featured-services .service-item:hover .readmore,
.featured-services .service-item:hover .icon i {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# About Us Section
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 32px;
  font-family: var(--font-secondary);
  color: #001f8d;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.about .content ul i {
  flex-shrink: 0;
  font-size: 48px;
  color: var(--color-primary);
  margin-right: 20px;
  line-height: 0;
}

.about .content ul h5 {
  font-size: 18px;
  font-weight: 700;
  color: #19335c;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%, rgba(13, 66, 255, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(13, 66, 255, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .card {
  border: 1px solid rgba(14, 29, 52, 0.15);
  background: #fff;
  position: relative;
  border-radius: 0;
  height: 100%;
}

.services .card .card-img {
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 0;
}

.services .card .card-img img {
  transition: 0.3s ease-in-out;
}

.services .card h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.services .card a {
  color: #001973;
  transition: 0.3;
}

.services .card a:hover {
  color: var(--color-primary);
}

.services .card p {
  padding: 0 30px;
  margin-bottom: 30px;
  color: var(--color-secondary);
  font-size: 15px;
}

.services .card:hover .card-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  /* background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)), url("../img/cta-bg.jpg") center center; */
  background-size: cover;
  padding: 100px 0;
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.call-to-action p {
  color: #fff;
}

.call-to-action .cta-btn {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.call-to-action .cta-btn:hover {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding-top: 80px;
}

.features .features-item+.features-item {
  margin-top: 100px;
}

@media (max-width: 640px) {
  .features .features-item+.features-item {
    margin-top: 40px;
  }
}

.features .features-item h3 {
  font-weight: 600;
  font-size: 26px;
  color: #001973;
}

.features .features-item ul {
  list-style: none;
  padding: 0;
}

.features .features-item ul li {
  padding-bottom: 10px;
}

.features .features-item ul li:last-child {
  padding-bottom: 0;
}

.features .features-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-primary);
}

.features .features-item p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  color: var(--color-secondary);
}

.pricing h4 {
  font-size: 48px;
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--font-primary);
  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: rgba(108, 117, 125, 0.8);
  font-size: 18px;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #6c757d;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul .na {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na i {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 4px;
  color: var(--color-primary);
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-primary);
  transition: 0.3s;
  border: 1px solid var(--color-primary);
}

.pricing .buy-btn:hover {
  background: var(--color-primary);
  color: #fff;
}

.pricing .featured {
  border-top-color: var(--color-primary);
}

.pricing .featured .buy-btn {
  background: var(--color-primary);
  color: #fff;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions Section
--------------------------------------------------------------*/
.faq {
  padding-top: 80px;
}

.faq .accordion-item {
  border: 0;
  margin-bottom: 10px;
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 30px 20px 60px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: var(--color-default);
  text-align: left;
  background: rgba(14, 29, 52, 0.03);
  border-radius: 5px;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  color: var(--color-primary);
  border-bottom: 0;
  box-shadow: none;
}

.faq .question-icon {
  position: absolute;
  top: 14px;
  left: 25px;
  font-size: 20px;
  color: var(--color-primary);
}

.faq .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
}

.faq .accordion-body {
  padding: 0 30px 25px 60px;
  border: 0;
  background: rgba(14, 29, 52, 0.03);
  border-radius: 5px;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  /* background: url("../img/testimonials-bg.jpg") no-repeat; */
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(14, 29, 52, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter .stats-item {
  padding: 30px;
  width: 100%;
}

.stats-counter .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--color-secondary);
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.stats-counter .stats-item span:after {
  content: "";
  position: absolute;
  display: block;
  width: 25px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 500;
  color: var(--color-secondary);
}

/*--------------------------------------------------------------
# Our Team Section
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid rgba(14, 29, 52, 0.15);
}

.team .member img {
  margin: -1px -1px 30px -1px;
}

.team .member .member-content {
  padding: 0 20px 30px 20px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #6c757d;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #6c757d;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: rgba(14, 29, 52, 0.5);
  transition: 0.3s;
}

.team .member .social a:hover {
  color: var(--color-primary);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Horizontal Pricing Section
--------------------------------------------------------------*/
.horizontal-pricing .pricing-item {
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  padding-bottom: 30px;
  background: #fff;
  height: 100%;
  position: relative;
}

.horizontal-pricing h3 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 24px;
  color: var(--color-secondary);
}

.horizontal-pricing h4 {
  font-size: 48px;
  color: var(--color-primary);
  font-weight: 400;
  font-family: var(--font-primary);
  margin-bottom: 25px;
  text-align: center;
}

.horizontal-pricing h4 sup {
  font-size: 28px;
}

.horizontal-pricing h4 span {
  color: rgba(108, 117, 125, 0.8);
  font-size: 18px;
}

.horizontal-pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #6c757d;
  text-align: left;
  line-height: 20px;
}

.horizontal-pricing ul li {
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.horizontal-pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.horizontal-pricing ul .na {
  color: rgba(108, 117, 125, 0.5);
}

.horizontal-pricing ul .na i {
  color: rgba(108, 117, 125, 0.5);
}

.horizontal-pricing ul .na span {
  text-decoration: line-through;
}

.horizontal-pricing .buy-btn {
  display: inline-block;
  padding: 12px 40px;
  border-radius: 4px;
  color: #fff;
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  font-family: var(--font-primary);
}

.horizontal-pricing .buy-btn:hover {
  background: #406aff;
  border-color: #406aff;
}

.horizontal-pricing .featured {
  background: var(--color-primary);
}

.horizontal-pricing .featured h3,
.horizontal-pricing .featured h4,
.horizontal-pricing .featured h4 span,
.horizontal-pricing .featured ul,
.horizontal-pricing .featured ul .na,
.horizontal-pricing .featured ul i,
.horizontal-pricing .featured ul .na i {
  color: #fff;
}

.horizontal-pricing .featured .buy-btn {
  background: var(--color-primary);
  color: #fff;
  border-color: #fff;
}

.horizontal-pricing .featured .buy-btn:hover {
  background: #fff;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Get a Quote Section
--------------------------------------------------------------*/
.get-a-quote .quote-bg {
  min-height: 500px;
  background-size: cover;
  background-position: center;
}

.get-a-quote .php-email-form {
  background: #f3f6fc;
  padding: 40px;
  height: 100%;
}

@media (max-width: 575px) {
  .get-a-quote .php-email-form {
    padding: 20px;
  }
}

.get-a-quote .php-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-a-quote .php-email-form h4 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px 0 0 0;
}

.get-a-quote .php-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-a-quote .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-a-quote .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-a-quote .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.get-a-quote .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.get-a-quote .php-email-form input,
.get-a-quote .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.get-a-quote .php-email-form input:focus,
.get-a-quote .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-a-quote .php-email-form input {
  padding: 12px 15px;
}

.get-a-quote .php-email-form textarea {
  padding: 12px 15px;
}

.get-a-quote .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.get-a-quote .php-email-form button[type=submit]:hover {
  background: rgba(13, 66, 255, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  font-size: 20px;
  background: var(--color-primary);
  color: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-secondary);
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(14, 29, 52, 0.8);
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(13, 66, 255, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d3dff3;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #c2cbdf;
  margin: 20px 0;
  color: var(--color-secondary);
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 80vh;
  background-color: none;
  /* background-image: url("../img/hero-bg.png"); */
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 120px 0 60px 0;
}

.hero h2 {
  margin-bottom: 20px;
  padding: 0;
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 575px) {
  .hero h2 {
    font-size: 30px;
  }
}

.hero p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 40px;
}

.hero form {
  padding: 10px;
  border-radius: 10px;
}

.hero form .form-control {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  margin-right: 10px;
  border: none !important;
  background: #fcf1dc !important;
}

.hero form .form-control:hover,
.hero form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.hero form .btn-primary {
  background-color: var(--color-primary);
  padding: 15px 20px;
  border-color: #F5DEB3;
  color: black;
}

.hero form .btn-primary:hover {
  background-color: #F5DEB3;
}

.hero .stats-item {
  padding: 30px;
  width: 100%;
}

.hero .stats-item span {
  font-size: 32px;
  display: block;
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

.hero .stats-item span:after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.hero .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 600;
}
</style>