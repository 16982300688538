export const album = [
  {
    title: 'MWC Las Vegas 2024',
    images: [
      {
        src: require('@/assets/images/mwc2.png'),
        // description: 'Description for image 1 of Exhibition 1'
      },
      {
        src: require('@/assets/images/mwc1.png'),
        // description: 'Description for image 2 of Exhibition 1'
      },
      {
        src: require('@/assets/images/mwc3.png'),
      }
    ]
  },
  {
    title: 'healthTECH JAPAN 2024',
    images: [
      {
        src: require('@/assets/images/japan3.png'),
      },
      {
        src: require('@/assets/images/japan2.png'),
      },
      {
        src: require('@/assets/images/japan1.png'),
      }
    ]
  },
  {
    title: 'hlth. Las Vegas 2024',
    images: [
      {
        src: require('@/assets/images/hlth1.png'),
      },
      {
        src: require('@/assets/images/hlth2.png'),
      },
      {
        src: require('@/assets/images/hlth3.png'),
      },
    ]
  },
  {
    title: 'AusBiotech Australia 2024',
    images: [
      {
        src: require('@/assets/images/aus3.png'),
      },
      {
        src: require('@/assets/images/aus2.png'),
      },
      {
        src: require('@/assets/images/aus1.png'),
      }
    ]
  },
];