<template>
    <div class="qnaWrite">
        <section id="hero" class="d-flex align-items-center justify-content-center">
            <div class="container" data-aos="fade-up">

                <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-xl-6 col-lg-8">
                        <h1 v-if="$store.state.language === 'ko'">문의사항</h1>
                        <h2 v-if="$store.state.language === 'ko'">제품 및 기타 문의 게시판입니다.</h2>

                        <h1 v-if="$store.state.language === 'en'">Questions</h1>
                        <h2 v-if="$store.state.language === 'en'">This is a bulletin board for products and other
                            inquiries.</h2>
                    </div>
                </div>

            </div>
        </section>
        <section id="faq" class="faq section-bg-noti">
            <div class="container" data-aos="fade-up">

                <div class="section-title-noti">

                    <!-- <h2>자주 묻는 질문들</h2>
      <p>스마트 체온계와 닥터인홈의 궁금증을 해결해드립니다.</p> -->
                </div>

                <div class="notice-table">
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h5 class="card-title">Table with hoverable rows</h5> -->

                                <!-- Table with hoverable rows -->
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th v-if="$store.state.language === 'ko'">제목</th>
                                            <th v-if="$store.state.language === 'en'">Title</th>

                                            <td>
                                                <input v-model="qnaTitle" name="qnaTitle" id="qnaTitle"
                                                    class="inputTypeText" placeholder maxlength="125" value type="text"
                                                    autocomplete="off">
                                            </td>

                                            <th v-if="$store.state.language === 'ko'">카테고리</th>
                                            <th v-if="$store.state.language === 'en'">Category</th>

                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                                        id="dropdownMenuButton" @click="toggleDropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        {{ selectedCategory || '카테고리' }}
                                                    </button>
                                                    <div class="dropdown-menu" :class="{ 'show': isDropdownOpen }"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#"
                                                            @click="filterByCategory('배송문의')">{{ '배송문의' }}</a>
                                                        <a class="dropdown-item" href="#"
                                                            @click="filterByCategory('상품문의')">{{ '상품문의' }}</a>
                                                        <a class="dropdown-item" href="#"
                                                            @click="filterByCategory('교환/취소/반품')">{{ '교환/취소/반품' }}</a>
                                                        <a class="dropdown-item" href="#"
                                                            @click="filterByCategory('기타')">{{ '기타' }}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th v-if="$store.state.language === 'ko'">작성자</th>
                                            <th v-if="$store.state.language === 'en'">Writer</th>

                                            <td colspan="3">
                                                <input name="qnaWriter" id="qnaWriter" class="inputTypeText" placeholder
                                                    maxlength="125" type="text" :value="userEmail" readonly>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="clear">
                                                <div>
                                                    <text-editor v-model="qnaContent" />
                                                    <button @click="saveHandler">저장하기</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="qnaList" style="text-align: center; margin: 20px;">
                    <router-link to="/question" class="qnaList-btn"
                        v-if="$store.state.language === 'ko'">목록</router-link>
                    <router-link to="/question" class="qnaList-btn"
                        v-if="$store.state.language === 'en'">List</router-link>
                </div>
            </div>

        </section>
    </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex'; // vuex store 사용
import TextEditor from "@/pages/TextEditor.vue";

export default {
    name: 'qnaWrite',
    components: {
        TextEditor
    },
    data() {
        return {
            qnaTitle: '',
            qnaContent: '',
            selectedCategory: '',
            isDropdownOpen: false,
            questions: [] // Added to store retrieved questions
        }
    },
    setup() {
        const db = ref(null); // IndexedDB 데이터베이스
        const dbName = 'myDatabase'; // 데이터베이스 이름
        const storeName = 'questions'; // 객체 저장소 이름

        const store = useStore(); // vuex store 가져오기
        const userEmail = computed(() => store.state.userEmail); // 현재 로그인된 사용자의 이메일


        // 데이터베이스에 연결하고 객체 저장소를 준비하는 함수
        const openDatabase = () => {
            return new Promise((resolve, reject) => {
                const request = window.indexedDB.open(dbName, 1);

                request.onerror = (event) => {
                    console.error('Database error:', event.target.error);
                    reject(event.target.error);
                };

                request.onsuccess = (event) => {
                    db.value = event.target.result;
                    console.log('Database opened successfully:', db.value);
                    resolve();
                };

                request.onupgradeneeded = (event) => {
                    const db = event.target.result;
                    if (!db.objectStoreNames.contains(storeName)) {
                        db.createObjectStore(storeName, { keyPath: 'qnaNum', autoIncrement: true });
                    }
                };
            });
        };

        // 문의 사항을 IndexedDB에 저장하는 함수
        const saveQuestion = (question) => {
            const transaction = db.value.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.add(question);

            request.onsuccess = () => {
                console.log('Question saved successfully:', question);
            };

            request.onerror = (event) => {
                console.error('Error saving question:', event.target.error);
            };
        };

        // 일반 함수로 변경하는 방법
        function getQuestions() {
            const transaction = db.value.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);

            const request = objectStore.getAll();

            request.onsuccess = (event) => {
                const questions = event.target.result;
                // 이곳에서 questions를 사용할 수 있습니다.
                console.log('Retrieved questions:', questions);
            };

            request.onerror = (event) => {
                console.error('Error retrieving questions:', event.target.error);
            };
        }

        onMounted(() => {
            openDatabase().then(() => {
                // 컴포넌트가 마운트될 때 데이터를 조회하여 콘솔에 출력
                getQuestions();
            });
        });

        return { saveQuestion, userEmail, db };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        filterByCategory(category) {
            this.selectedCategory = category;
            this.isDropdownOpen = false;
        },
        saveHandler() {
            // 현재 시각을 연도-월-일 형식으로 변환
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            // 저장할 문의 사항 생성
            const question = {
                qnaTitle: this.qnaTitle,
                qnaWriter: this.userEmail,
                category: this.selectedCategory,
                qnaContent: this.qnaContent,
                qnaDate: formattedDate
            };

            // IndexedDB에 문의 사항 저장
            this.saveQuestion(question);
            this.$router.push('/question');

        }
    },
    mounted() {
        console.log('현재 로그인된 사용자의 이메일:', this.userEmail);
    }
}
</script>
<style src="@/assets//css/editer.css"></style>
<style scoped>
a {
    color: #5777ba;
    text-decoration: none;
}

a:hover {
    color: #82a0e2;
    text-decoration: none;
}

.dropdown {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section-noti {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg-noti {
    background-color: #fff;
    padding: 60px 0;
}

.section-title-noti {
    /* text-align: center; */
    padding-bottom: 30px;
}

.section-title-noti h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5777ba;
}

.section-title-noti p {
    margin-bottom: 0;
}


/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .notice-table {
    padding: 0 100px;
}

.faq .notice-table ul {
    padding: 0;
    list-style: none;
}

.faq .notice-table li+li {
    margin-top: 15px;
}

.faq .notice-table li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .notice-table a {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
}


.faq .notice-table p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

@media (max-width: 1200px) {
    .faq .accordion-list {
        padding: 0;
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    min-height: 30vh;
    /* background: url("@/assets/images/intro-3.png") top center; */
    background-color: #f2f5fa;
    background-size: cover;
    position: relative;
}

#hero:before {
    content: "";
    /* background: rgba(0, 0, 0, 0.1); */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
    padding-top: 10px;
    text-align: center;
}

#hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: #5777ba;
}

#hero h1 span {
    color: #5777ba;
}

#hero h2 {
    color: rgba(86, 108, 110, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
}

#hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #5777ba;
}

#hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
}

#hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
}

#hero .icon-box h3 a:hover {
    color: #5777ba;
}

#hero .icon-box:hover {
    border-color: #5777ba;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 20px;
        line-height: 24px;
    }
}
</style>